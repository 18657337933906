import { useQuery, UseQueryResult } from 'react-query';
import StreamKeyListItem from '../entities/stream-key-list-item';
import Api from './api';
import { ListResponse } from '../entities/list-response';

export const GET_STREAM_KEY_CACHE_KEY = 'list-stream-keys';

export function useGetStreamKeys(): UseQueryResult<ListResponse<StreamKeyListItem>> {
  return useQuery(GET_STREAM_KEY_CACHE_KEY, async () => {
    return await Api.get('/stream-keys');
  });
}
