import { useMutation, UseMutationResult } from 'react-query';
import Api from '@/adapters/api';
import { useState } from 'react';

export function useUploadFile<T>(): UseMutationResult<void, unknown, { url: string; data: T }> & {
  progress: number | null;
} {
  const [progress, setProgress] = useState<number | null>(null);

  return {
    progress,
    ...useMutation(async ({ url, data }: { url: string; data: T }) => {
      setProgress(0);
      return Api.putFile(url, data, (progress) => {
        setProgress(progress);
      }).finally(() => {
        setProgress(null);
      });
    }, {}),
  };
}
