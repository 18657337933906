/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import styles from '../Integrations.module.scss';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import WowzaLogo from '@/assets/images/wowzaLogo.svg';
import JWPLogo from '@/assets/images/jwpLogo.svg';
import PrimaryButton from '@/components/common/PrimaryButton';
import DefaultButton from '@/components/common/DefaultButton';
import TextField from '@mui/material/TextField';
import {
  useAddPlatform,
  useDisconnectIntegration,
  useGetIntegrations,
} from '@/adapters/integrations';
import Loading from '@/components/Loading';
import CircularProgress from '@mui/material/CircularProgress';

interface AppRequirement {
  [key: string]: string;
}

interface App {
  id: string;
  logo: string;
  name: string;
  platform_type: string;
  description: string;
  requirements: AppRequirement[];
}

interface IntegratedApps {
  [key: string]: App;
}

const integratedApp: IntegratedApps = {
  app1: {
    id: 'wowza',
    logo: WowzaLogo,
    name: 'Wowza Streaming Engine',
    platform_type: 'wowza',
    description:
      'The embedded solution platform for Solution Builders. The Wowza platform offers end-to-end video solutions, for both live video and VOD streaming.',
    requirements: [
      {
        token: '',
      },
    ],
  },
  app2: {
    id: 'jwp',
    logo: JWPLogo,
    name: 'JW Player',
    platform_type: 'jwp',
    description:
      'Video Platform for Video-Driven Companies. Easily stream video at any scale, expand & engage your audiences on any screen, and monetize your content.',
    requirements: [
      {
        api_key: '',
        site_id: '',
      },
    ],
  },
};

const CloudStorage: FC = () => {
  const { data: integrations = [], isLoading, refetch } = useGetIntegrations();
  const { mutateAsync: addPlatform, isLoading: isLoadingAddPlatform } = useAddPlatform();
  const { mutateAsync: deleteIntegration } = useDisconnectIntegration();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [connected, setConnected] = React.useState<boolean>(false); //Currently used to render the disconnect button
  const [inputValue, setInputValue] = React.useState<any>(null);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [empty, setIsEmpty] = React.useState<boolean>(false);

  const handleExpansion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    setInputValue({ ...inputValue, [key]: e.target.value });
  };

  const handleConnectWowza = async (type: string): Promise<void> => {
    try {
      await addPlatform({
        platformType: type,
        payload: inputValue,
      });

      // If the asynchronous operation is successful, update the state
      setIsSuccess(true);
      setConnected(true);
      await refetch();
    } catch (error) {
      console.error('Error connecting to Wowza:', error);
      setIsSuccess(false);
    }
  };

  const handleDisconnectWowza = async (id: string): Promise<void> => {
    try {
      await deleteIntegration(id);
      await refetch();
    } catch (error) {
      console.error('Error connecting to Wowza:', error);
      setIsSuccess(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.cloudStorage}>
          {Object.entries(integratedApp).map(([key, app]) => (
            <div key={key}>
              <Accordion
                key={app.id}
                expanded={expanded === app.id}
                onChange={handleExpansion(app.id)}
                className={styles.accordion}
              >
                <AccordionSummary>
                  <div className={styles.container}>
                    <div className={styles.summary}>
                      <img src={app.logo} alt={`${app.name} Logo`} />
                      <Typography fontWeight={'bold'} className={styles.summaryTitle}>
                        {app.name}
                      </Typography>
                    </div>
                    <Typography className={styles.summaryDescription}>{app.description}</Typography>
                    {expanded === app.id ? null : (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {integrations.some((integration: any) => integration.platform[app.id]) ? (
                          <DefaultButton
                            className={styles.summaryButton}
                            onClick={() => handleDisconnectWowza(app.id)}
                          >
                            Disconnect
                          </DefaultButton>
                        ) : (
                          <PrimaryButton className={styles.summaryButton}>Connect</PrimaryButton>
                        )}
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.details}>
                    {app.requirements.map((requirement, reqIndex) => (
                      <div key={reqIndex}>
                        {Object.entries(requirement).map(([key, value], pairIndex) => (
                          <TextField
                            key={pairIndex}
                            label={key}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              handleInputChange(event, key)
                            }
                            id={`outlined-basic-${reqIndex}-${pairIndex}`}
                            size="small"
                            sx={{ paddingBottom: '15px' }}
                          />
                        ))}
                      </div>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                      {isLoadingAddPlatform ? (
                        <CircularProgress size={20} sx={{ marginRight: 1 }} />
                      ) : (
                        <PrimaryButton
                          className={styles.summaryButton}
                          onClick={() => handleConnectWowza(app.id)}
                          disabled={isSuccess}
                        >
                          Connect
                        </PrimaryButton>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CloudStorage;
