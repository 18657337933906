import React, { useState } from 'react';
import { UserState } from '@/entities/subscription';

export interface UserContextValue {
  userState?: UserState;
  setUserState?(value: UserState | ((prevState: UserState) => UserState)): void;
}

export const UserContext = React.createContext<UserContextValue>({});

export interface UserProviderProps {
  children: React.ReactNode;
  userInitialState: UserState;
}

export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  userInitialState,
}: UserProviderProps) => {
  const [userState, setUserState] = useState<UserState>(userInitialState);
  return (
    <UserContext.Provider value={{ userState, setUserState }}>{children}</UserContext.Provider>
  );
};
