/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import { UserContext } from '@/contexts/UserContext';
import { useContext } from 'react';

const useAuthorization = () => {
  const { userState } = useContext(UserContext);

  // hasRole now accepts a single role or an array of roles
  const hasRole = (requiredRoles: string | string[]) => {
    if (!userState?.role) {
      return false;
    }

    if (Array.isArray(requiredRoles)) {
      return requiredRoles.includes(userState.role);
    } else {
      return userState.role === requiredRoles;
    }
  };

  return { hasRole };
};

export default useAuthorization;
