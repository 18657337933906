import { ProjectProvider } from '@/contexts/ProjectContext';
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useGetProject, useEditProject } from '@/adapters/projects';
import { Link, useParams } from 'react-router-dom';
import Loading from '@/components/Loading';
import { TranscriptProvider } from '@/contexts/TranscriptContext';
import styles from '@/pages/ProjectVideo/ProjectVideo.module.scss';
import { PROJECTS_URL } from '@/routePaths';
import leftSlackIcon from '@/assets/images/left-slack.svg';
import TranscriptToolbar from '@/components/Transcript/TranscriptToolbar';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Transcript from '@/components/Transcript';
import Waveform from '@/components/Waveform/index';
import resizerIcon from '@/assets/images/horizontal align center.svg';
import EditableLabel from '@//components/EditableLabel';
import OrientationOverlay from '@/components/OrientationOverlay';
{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}
const Project = (): ReactElement => {
  const { projectId } = useParams<{ projectId: string }>();
  const videoId = 'original';

  const videoRef = useRef<HTMLVideoElement>(null);

  const { data: project, isLoading } = useGetProject(projectId!);
  const { mutateAsync: editProject } = useEditProject(projectId!);

  const [videoCurrentTime, setVideoCurrentTime] = useState<number | null>(null);
  const [disablePlay, setDisablePlay] = useState<boolean>(false);
  const [timeStamp, setTimeStamp] = useState<number | null>(null);

  const resizerRef = useRef<HTMLDivElement | null>(null);
  const leftSideRef = useRef<HTMLDivElement | null>(null);

  const onTimeUpdate = useCallback((event: SyntheticEvent<HTMLVideoElement, Event>): void => {
    setVideoCurrentTime(event.currentTarget.currentTime);
    setTimeStamp(event.currentTarget.currentTime);
  }, []);

  const isMobileDevice = (): boolean => {
    return window.innerWidth <= 768;
  };

  const [width, setWidth] = useState<number>(700); //initial width
  const [transcriptionHeight, setTranscriptionHeight] = useState<number>(
    isMobileDevice() ? 150 : 300,
  );

  const mouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ): void => {
    const initialX = 'touches' in e ? e.touches[0].clientX : e.clientX;

    const initialWidth = leftSideRef.current?.offsetWidth || 0;

    const mouseMoveHandler = (event: MouseEvent | TouchEvent): void => {
      // Extracting clientX for both mouse and touch events
      const currentX = 'touches' in event ? event.touches[0].clientX : event.clientX;
      const dx = currentX - initialX;
      const newWidth = initialWidth + dx;

      if (newWidth >= 22) {
        setWidth(newWidth);
        updateHeight();
      }
    };

    const mouseUpHandler = (): void => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('touchmove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      document.removeEventListener('touchend', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('touchmove', mouseMoveHandler, { passive: false });
    document.addEventListener('mouseup', mouseUpHandler);
    document.addEventListener('touchend', mouseUpHandler);
  };

  const updateHeight = (): void => {
    const parentHeight = document.getElementById('transcriptionVideoContainer')?.clientHeight;
    if (parentHeight) {
      const newHeight = Math.min(parentHeight) - 22;
      setTranscriptionHeight(newHeight);
    }
  };

  useEffect(() => {
    if (!disablePlay) {
      const handleKeyDown = (e: KeyboardEvent): void => {
        const isInputField =
          (e.target as HTMLElement).tagName === 'INPUT' ||
          (e.target as HTMLElement).tagName === 'TEXTAREA';
        if (e.key === ' ' && videoRef.current && !isInputField) {
          e.preventDefault();
          if (videoRef.current.paused) {
            (function (): void {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (videoRef.current) {
                videoRef.current
                  .play()
                  .then(() => {
                    console.log('video played');
                  })
                  .catch(() => {
                    console.log('error while playing video');
                  });
              }
            })();
          } else {
            videoRef.current.pause();
          }
        }
      };

      document.addEventListener('keypress', handleKeyDown);

      // Don't forget to clean up
      return function cleanup() {
        document.removeEventListener('keypress', handleKeyDown);
      };
    }
  }, [disablePlay]);
  const containerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <>
      {isLoading || !project ? (
        <Loading />
      ) : (
        <ProjectProvider project={project}>
          <TranscriptProvider
            project={project}
            videoId={videoId}
            currentTime={videoCurrentTime}
            containerRef={containerRef}
            videoRef={videoRef}
            setTimeStamp={(timeStamp: number) => setTimeStamp(timeStamp)}
          >
            <div className={styles.projectDetailInner}>
              <div className={styles.routePaths}>
                <div>
                  <Link to={PROJECTS_URL} className={`${styles.burgerContentItem} projects`}>
                    Projects
                  </Link>
                </div>
                <div className={styles.leftSlack}>
                  <img src={leftSlackIcon} alt="left" />
                </div>
                <div className={styles.currentProjectName}>
                  <Link to={PROJECTS_URL} className={`${styles.burgerContentItem} projects`}>
                    {project.name}
                  </Link>
                </div>
                <div className={styles.leftSlack}>
                  <img src={leftSlackIcon} alt="left" />
                </div>
                <div>Editing</div>
              </div>
              <div className={styles.projectDetailHeader}>
                <EditableLabel
                  text={project.name}
                  onSave={(text) => editProject({ name: text })}
                  testId="project-name"
                />
              </div>
              <div>
                <TranscriptToolbar disablePlay={disablePlay} setDisablePlay={setDisablePlay} />
              </div>
              <Box sx={{ maxWidth: '100%' }}>
                <Grid container columnSpacing={{}} justifyContent={'space-between'}>
                  <div className={styles.sbCover}>
                    <div
                      className={styles.leftSide}
                      ref={leftSideRef}
                      style={{ width: `${width}px` }}
                    >
                      <div
                        className={styles.resizer}
                        ref={resizerRef}
                        onMouseDown={mouseDownHandler}
                        onTouchStart={mouseDownHandler}
                      >
                        <div className={styles.resizerButton}>
                          <img src={resizerIcon} alt="resizerIcon" className={styles.resizerIcon} />
                        </div>
                      </div>
                      <div className={styles.leftSideHeader}>
                        <Grid sx={{ width: '97%', height: '100%' }} xs={6}>
                          <div style={{ maxHeight: `${transcriptionHeight}px`, overflow: 'auto' }}>
                            <Transcript height={transcriptionHeight} />
                          </div>
                        </Grid>
                      </div>
                    </div>
                    <div className={styles.rightSide} style={{ width: `40%` }}>
                      <video
                        controls={true}
                        playsInline
                        className={styles.video}
                        ref={videoRef}
                        onTimeUpdate={onTimeUpdate}
                        onLoadedData={updateHeight}
                        id="transcriptionVideoContainer"
                        preload="auto"
                      >
                        <source src={project.original?.fullPath} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <Grid xs={12} md={12} lg={12} className="testClass">
                    {project.audio?.waveFormData && project.audio.waveFormData.length > 0 ? (
                      <Waveform
                        audioUrl={project.audio.fullPath}
                        timeStamp={timeStamp}
                        setTimeStamp={(time: number) => {
                          videoRef.current!.currentTime = time;
                        }}
                        project={project}
                      />
                    ) : (
                      <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                        <strong>Waveform Data not ready yet.</strong>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </div>
          </TranscriptProvider>
          <OrientationOverlay />
        </ProjectProvider>
      )}
    </>
  );
};

export default Project;
