import React from 'react';
import Project from '@/entities/project';

export interface ProjectContextValue {
  project?: Project | undefined | null;
  video?: string | undefined | null;
}

export const ProjectContext = React.createContext<ProjectContextValue>({});

export interface ProjectProviderProps {
  children: React.ReactNode;
  project: Project;
}

export const ProjectProvider: React.FC<ProjectProviderProps> = ({
  children,
  project,
}: ProjectProviderProps) => {
  return <ProjectContext.Provider value={{ project }}>{children}</ProjectContext.Provider>;
};
