import React from 'react';
import { FormControl, Input, Grid, Box, Tooltip } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { HandleFileNameChangeProps, TestSegmentProps } from '@/types';
import { PeaksInstance } from 'peaks.js';
import { HandlePlayheadSeekProps, CreateOrDeleteSingleSegmentProps } from '@/types';
import { formatAxisTime, reFormatAxisTime } from '../../lib/general-utils';
import { useState, useRef, useEffect } from 'react';
import styles from '@/pages/ProjectVideo/ProjectVideo.module.scss';

const ClipGrid: React.FC<{
  isReady: boolean;
  segments: TestSegmentProps[];
  setSegments: React.Dispatch<React.SetStateAction<TestSegmentProps[]>>;
  myPeaks: PeaksInstance;
  handlePlayheadSeek: HandlePlayheadSeekProps;
  deleteSingleSegment: CreateOrDeleteSingleSegmentProps;
  createSingleSegment: CreateOrDeleteSingleSegmentProps;
  saveNameChange(): void;
  handleFileNameChange: HandleFileNameChangeProps;
  saving: boolean;
  editClipStartPoint(evt: any): void;
  editClipEndPoint(evt: any): void;
  saveTopTail(): void;
}> = ({
  isReady,
  segments,
  handlePlayheadSeek,
  deleteSingleSegment,
  createSingleSegment,
  handleFileNameChange,
  editClipStartPoint,
  editClipEndPoint,
  saving,
  saveTopTail,
  saveNameChange,
}) => {
  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      // Call the changeNameFunction
      saveNameChange();
    }
  };

  const [editedTime, setEditedTime] = useState<string>('');
  const [useDefaultValueStartTime, setUseDefaultValueStartTime] = useState<boolean>(false);
  const [useDefaultValueEndTime, setUseDefaultValueEndTime] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setUseDefaultValueStartTime(false);
        setUseDefaultValueEndTime(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    seg: TestSegmentProps,
  ): void => {
    // setEditedTime(e.target.value);
    const isValidInput = /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/.test(e.target.value);
    if (isValidInput) {
      setEditedTime(e.target.value);
    }
  };

  const validation = (input: string): boolean => {
    return /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/.test(input);
  };
  return (
    // Create an overflow scrollable container to hold clip information
    <Box sx={{ overflowY: 'scroll', maxHeight: '35vh' }}>
      {/* Map over the segments array and create a grid item for each clip */}
      {segments.length > 0 &&
        isReady &&
        segments.map((seg, idx) => (
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              marginBottom: '.5rem',
              padding: '.5rem',
              borderRadius: '.3rem',
            }}
            key={idx}
          >
            <Grid item xs={3}>
              <FormControl error={seg.formErrors.fileNameError}>
                <Input
                  disabled={seg.status === 'Processing'}
                  onClick={() => handlePlayheadSeek(seg.id, true)}
                  value={seg.fileName}
                  onChange={(evt) => handleFileNameChange(seg.id!, evt)}
                  onKeyDown={handleEnterKeyPress}
                />
                {seg.formErrors.fileNameError && (
                  <FormHelperText error>File Name is required.</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="Click for clip start" arrow placement="left">
                {/* <Box
                  onClick={() => {
                    if (seg.status === 'Processed') {
                      handlePlayheadSeek(seg.id, true);
                    }
                  }}
                  sx={{
                    cursor: 'pointer',
                    padding: '1rem',
                    borderRadius: '0.3rem',
                    height: '40px',
                    border: '1px solid lightgray',
                    textAlign: 'center',
                  }}
                >
                  {formatAxisTime(seg.startTime)}
                </Box> */}
                <div
                  className={styles.timeCodeWrapper}
                  onClick={() => {
                    if (seg.status === 'Processed' || seg.status === 'Staging') {
                      handlePlayheadSeek(seg.id, true);
                    }
                    setUseDefaultValueStartTime(true);
                  }}
                >
                  <input
                    type="text"
                    id="startTime"
                    className={styles.timeCode}
                    defaultValue={
                      useDefaultValueStartTime ? formatAxisTime(seg.startTime) : undefined
                    }
                    value={useDefaultValueStartTime ? undefined : formatAxisTime(seg.startTime)}
                    ref={inputRef}
                    onChange={(e) => handleInputChange(e, seg)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && validation(editedTime)) {
                        const updatedSegment = {
                          segment: {
                            ...seg,
                            startTime: reFormatAxisTime(editedTime),
                          },
                        };
                        editClipStartPoint(updatedSegment);
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="Click for clip end" arrow placement="left">
                {/* <Box
                  onClick={() => {
                    if (seg.status === 'Processed') {
                      handlePlayheadSeek(seg.id);
                    }
                  }}
                  sx={{
                    cursor: 'pointer',
                    padding: '1rem',
                    borderRadius: '0.3rem',
                    height: '40px',
                    border: '1px solid lightgray',
                    textAlign: 'center',
                  }}
                >
                  {formatAxisTime(seg.endTime)}
                </Box> */}
                <div
                  className={styles.timeCodeWrapper}
                  onClick={() => {
                    if (seg.status === 'Processed' || seg.status === 'Staging') {
                      handlePlayheadSeek(seg.id);
                    }
                    setUseDefaultValueEndTime(true);
                  }}
                >
                  <input
                    type="text"
                    id="endTime"
                    className={styles.timeCode}
                    defaultValue={useDefaultValueEndTime ? formatAxisTime(seg.endTime) : undefined}
                    value={useDefaultValueEndTime ? undefined : formatAxisTime(seg.endTime)}
                    ref={inputRef}
                    onChange={(e) => handleInputChange(e, seg)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && validation(editedTime)) {
                        const updatedSegment = {
                          segment: {
                            ...seg,
                            endTime: reFormatAxisTime(editedTime),
                          },
                        };
                        editClipEndPoint(updatedSegment);
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex' }}>
              {seg.status === 'Processed' ? (
                <Button
                  variant="contained"
                  sx={{
                    margin: '2px',
                    fontSize: '0.6rem',
                    '@media (min-width:960px)': {
                      fontSize: '0.8rem',
                    },
                  }}
                >
                  Processed
                </Button>
              ) : (
                <Button
                  disabled={
                    seg.formErrors.isCreated ||
                    seg.fileName === '' ||
                    saving ||
                    seg.status === 'InReview' ||
                    seg.status === 'Processing'
                  }
                  variant="outlined"
                  onClick={() => {
                    saveTopTail();
                    createSingleSegment(seg.id!);
                  }}
                  sx={{ margin: '2px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {seg.status === 'Staging' ? 'Create' : null}
                    {seg.status === 'Processing' && (
                      <CircularProgress size={20} sx={{ marginRight: 1 }} />
                    )}
                    {seg.status === 'Processing' ? ' Processing' : null}
                  </div>
                </Button>
              )}
              <Button
                variant="outlined"
                disabled={saving || seg.status === 'InReview' || seg.status === 'Processing'}
                onClick={() => {
                  deleteSingleSegment(seg.id!);
                }}
                sx={{
                  margin: '2px',
                  fontSize: '0.6rem',
                  '@media (min-width:960px)': {
                    fontSize: '0.8rem',
                  },
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default ClipGrid;
