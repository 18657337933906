import { FC, useEffect, useState, useRef } from 'react';
import styles from '@/components/OrientationOverlay/Orientation.module.scss';
import rotationGif from '@/assets/images/rotate-phone.gif';

const OrientationOverlay: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 700);
  const [isPortrait, setIsPortrait] = useState<boolean>(
    window.matchMedia('(orientation: portrait)').matches,
  );

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const handleOrientationChange = (e: MediaQueryListEvent): void => {
      setIsPortrait(e.matches);
    };

    const mediaQuery = window.matchMedia('(orientation: portrait)');
    mediaQuery.addEventListener('change', handleOrientationChange);

    // Set up the resize observer
    resizeObserverRef.current = new ResizeObserver(() => {
      setIsMobile(() => window.innerWidth <= 700);
    });

    // Start observing the document body
    resizeObserverRef.current.observe(document.body);

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);

      // Disconnect the resize observer
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  console.log('isMobile', isMobile);
  console.log('isPortrait', isPortrait);

  return (
    <div className={`${styles.overlay} ${isMobile ? styles.show : styles.hide}`}>
      {isMobile && isPortrait && (
        <div className="overlay-content">
          <img src={rotationGif} alt="Rotate your device" />
          <p>Rotate screen to Landscape</p>
          <p>To enable editing mode</p>
        </div>
      )}
    </div>
  );
};

export default OrientationOverlay;
