import axios, { AxiosRequestConfig } from 'axios';

interface AuthTokens {
  authToken: string;
  idToken: string;
}
export default class Api {
  private static authTokens: AuthTokens;
  private static readonly axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
  });

  public static setAuthResult(authTokens: AuthTokens): void {
    this.authTokens = authTokens;

    this.axiosInstance.interceptors.request.use(this.addAuthHeaders);
  }

  private static addAuthHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
    (config.headers as Record<string, string>)[
      'Authorization'
    ] = `Bearer ${this.authTokens.authToken}`;
    (config.headers as Record<string, string>)['Identity'] = `Token ${this.authTokens.idToken}`;

    return config;
  };

  public static async get<T>(url: string): Promise<T> {
    const response = await this.axiosInstance.get(url);
    return response.data as T;
  }

  public static async delete<T>(url: string): Promise<T> {
    const response = await this.axiosInstance.delete(url);
    return response.data as T;
  }

  public static async getFile(url: string, responseHandler: (data: Blob) => void): Promise<void> {
    await axios
      .create()
      .get(url, {
        responseType: 'blob',
        headers: {},
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((response) => responseHandler(new Blob([response.data])));
  }

  public static async patch<T>(url: string, value: T): Promise<void> {
    await this.axiosInstance.patch(url, value);
  }

  public static async post<T>(url: string, value: T): Promise<T> {
    return (await this.axiosInstance.post(url, value)).data as T;
  }

  public static async putFile<T>(
    url: string,
    data: T,
    onProgress: (progress: number) => void,
  ): Promise<void> {
    await axios.create().put(url, data, {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        onProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    });
  }
}
