import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './ConfirmCancelModal.module.scss';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from '@/components/common/PrimaryButton';
import TextField from '@mui/material/TextField';
import { SUBSCRIPTION_PLANS_URL } from '@/routePaths';

/* istanbul ignore file */
interface Props {
  open: boolean;
  handleClose(): void;
  handleCancelReason(cancelReason: string): Promise<void>;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  minWidth: '300px',
  maxWidth: '700px',
  bgcolor: 'var(--white)',
};

const ConfirmCancelModal: FC<Props> = ({ open, handleClose, handleCancelReason }) => {
  const [cancelReason, setCancelReason] = useState<string>('');
  const navigate = useNavigate();

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose} disableScrollLock>
      <Box sx={style}>
        <div className={styles.cancelModal}>
          <Typography className={styles.cancelModalTitle}>Subscription Cancelled</Typography>
          <div className={styles.closeMark} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </div>
          <div className={styles.content}>
            <Typography className={styles.notation}>
              This is a confirmation that you Pro plan has been cancelled as per your request.
            </Typography>
            <div className={styles.sadContent}>
              <Typography className={styles.main}>We are sad to see you go.</Typography>
              <Typography className={styles.sub}>
                Before you leave, kindly let us know you reason for cancelling the subscription.We
                value your feedback and would like to continue improving our platform.
              </Typography>
            </div>
            <Typography className={styles.textTitle}>Reason for Cancellation</Typography>
            <TextField
              className={styles.textField}
              multiline
              rows={'3'}
              value={cancelReason}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCancelReason(event.target.value);
              }}
            />
            <Typography className={styles.bottom}>
              If you change your mind feel free to
              <Link to={SUBSCRIPTION_PLANS_URL} className={styles.link}>
                {' '}
                restart your plan{' '}
              </Link>
              to enjoy video editing in real time, automatically, by AI
            </Typography>
          </div>
          <div className={styles.buttons}>
            <PrimaryButton
              className={styles.backButton}
              onClick={() => navigate(SUBSCRIPTION_PLANS_URL)}
            >
              <Typography className={styles.buttonLabel}>Restart Plan Now</Typography>
            </PrimaryButton>
            <PrimaryButton
              className={styles.confirmButton}
              onClick={() => handleCancelReason(cancelReason)}
            >
              <Typography className={styles.buttonLabel}>I understand</Typography>
            </PrimaryButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmCancelModal;
