import classNames from 'classnames';
import { FC, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  PROJECTS_URL,
  BRAND_KIT_URL,
  STREAM_KEYS_URL,
  TUTORIALS_URL,
  INTEGRATIONS_URL,
  SUBSCRIPTION_HISTORY_URL,
} from '../../routePaths';
import styles from './Burger.module.scss';

/* istanbul ignore file */

const Burger: FC = () => {
  const [showBurger, setShowBurger] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const showBurgerHandler = (): void => {
    setShowBurger((current) => !current);
  };

  const handleClickOutside = (event: Event): void => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowBurger(false);
    }
  };

  useEffect(() => {
    if (showBurger) {
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [showBurger]);

  return (
    <div ref={wrapperRef}>
      <div
        className={classNames({
          [styles.burger]: true,
          [styles.burgerActive]: showBurger,
        })}
        onClick={showBurgerHandler}
      >
        <span></span>
      </div>
      <div
        className={classNames({
          [styles.burgerContent]: true,
          [styles.active]: showBurger,
        })}
      >
        <Link to={PROJECTS_URL} className={styles.burgerContentItem}>
          Projects
        </Link>

        <Link to={BRAND_KIT_URL} className={styles.burgerContentItem}>
          Brand Kit
        </Link>

        <Link to={STREAM_KEYS_URL} className={styles.burgerContentItem}>
          Stream Keys
        </Link>

        <Link to={SUBSCRIPTION_HISTORY_URL} className={styles.burgerContentItem}>
          Order History
        </Link>

        <Link to={INTEGRATIONS_URL} className={styles.burgerContentItem}>
          Integrations
        </Link>

        <Link to={TUTORIALS_URL} className={styles.burgerContentItem} target="_blank">
          Tutorials
        </Link>
      </div>
    </div>
  );
};

export default Burger;
