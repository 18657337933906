import React, { FC, useContext, useEffect, useState } from 'react';
import { BrandKitAssets, useGetAssets, useUploadAssets, useUploadFonts } from '@/adapters/assets';
import BrandKitVideoItem from '@/components/BrandKitVideoItem';
// import Assets from '../../entities/brand-kit-list-item';
import { BrandKitAsset } from '../../entities/brand-kit-list-item';
import { FileUpload } from '@/components/FileUpload';
import Loading from '../../components/Loading';
import styles from './BrandKit.module.scss';
import { UserContext } from '@/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import colorIcon from '@/assets/images/paint.svg';
import { UserSubscriptionState } from '@/entities/subscription';
import FontPicker from 'font-picker-react';
import { TextField, SelectChangeEvent } from '@mui/material';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { useUploadColor } from '../../adapters/assets';

/* istanbul ignore file */
{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}

const BrandKit: FC = () => {
  const { data: brandKit, isLoading } = useGetAssets();
  const { mutateAsync: uploadBrandKit } = useUploadAssets();
  const { mutateAsync: uploadBrandFont } = useUploadFonts();
  const { mutateAsync: uploadBrandColor } = useUploadColor();
  const { userState } = useContext(UserContext);
  const navigate = useNavigate();
  const [state, setState] = useState<UserSubscriptionState[]>([]);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [activeFontFamily, setActiveFontFamily] = useState<string>(
    // initialActiveFontFamily ??
    'Roboto',
  );
  // const [logoPosition, setLogoPosition] = useState<string>('Top Right');

  //Function has a switch case which returns a ReactNode with the correct copy for the Brand Kit
  const getDescription = (type: string): React.ReactNode => {
    switch (type) {
      case 'intro':
        return (
          <>
            This is the title at the beginning of your video. Choose a <b>png</b> image or a short{' '}
            <b>mp4</b> up to <b>1920x1080</b> pixels with a maximum size of 100Mb.
          </>
        );
      case 'outro':
        return (
          <>
            This is the title at the end of your video. Choose a <b>png</b> image or a short{' '}
            <b>mp4</b> up to <b>1920x1080</b> pixels with a maximum size of 100Mb.
          </>
        );
      case 'logo':
        return (
          <>
            Your video logo is the core part of your video. It will be displayed throughout your
            video and should be defined as a trasparent PNG and maximum size of 5Mb.
          </>
        );
      case 'font':
        return <>Your primary font will apply to any text overlays on your videos.</>;
      case 'color':
        return <>Your text color will apply to any text overlays on your videos.</>;
    }
  };

  useEffect(() => {
    if (userState) {
      setState(
        userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        ),
      );
    }
  }, [userState]);

  // const handleLogoPosition = (event: SelectChangeEvent): void => {
  //   setLogoPosition(event.target.value);
  // };

  const uploadFont = async (font: string): Promise<void> => {
    await uploadBrandFont({ type: 'font', font: font });
  };

  const uploadColor = async (color: string): Promise<void> => {
    await uploadBrandColor({ type: 'color', color: color });
  };

  const handleColorChange = async (event: SelectChangeEvent): Promise<void> => {
    setSelectedColor(event.target.value);
    await uploadColor(event.target.value);
  };
  const handleTextFieldChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setSelectedColor(event.target.value);
    await uploadColor(event.target.value);
  };
  return (
    <div className={styles.brandKit}>
      <h1 className={styles.brandKitHeadTitle}>Brand Kit</h1>
      {isLoading ? (
        <Loading />
      ) : brandKit ? (
        Object.keys(brandKit).map((label: string, index: number) => {
          const temp: BrandKitAsset = {
            intro: brandKit.intro,
            logo: brandKit.logo,
            outro: brandKit.outro,
          };
          if (label !== 'font' && label !== 'color') {
            const path = temp[label as keyof BrandKitAsset].path as string;

            return (
              <div className={styles.brandKitItem} key={index}>
                <div className={styles.brandKitItemLeft}>
                  <div className={styles.brandKitItemElem}>
                    <div className={styles.brandKitItemTitle}>
                      {label.charAt(0).toUpperCase() + label.slice(1)}
                    </div>
                    <div className={styles.brandKitItemText}>{getDescription(label)}</div>
                  </div>
                </div>
                <div
                  className={styles.brandKitItemRight}
                  style={{
                    opacity:
                      state.length === 0 && userState && userState.trialDays === 0 ? '0.5' : '10',
                  }}
                  onClick={(event) => {
                    if (state.length === 0 && userState && userState.trialDays === 0) {
                      event.preventDefault();
                      navigate('/subscription/plans');
                    }
                  }}
                >
                  {label != 'font' ? (
                    <div
                      className={[styles.brandKitItemElem, styles.brandKitItemElemRow].join(' ')}
                    >
                      {path && (
                        <div className={styles.brandKitItemElemVideo}>
                          <BrandKitVideoItem path={path} controls key={index} />
                        </div>
                      )}
                      <div data-testid={'file'}>
                        <FileUpload
                          styleEl="panel"
                          fileTypes={['.png', '.mp4']}
                          onChange={(file: File) =>
                            uploadBrandKit({ type: label as unknown as BrandKitAssets, file })
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* {label === 'logo' && (
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        value={logoPosition}
                        onChange={handleLogoPosition}
                        sx={{ height: '40px', borderRadius: '8px' }}
                      >
                        <MenuItem value={'Top Right'}>Top Right</MenuItem>
                        <MenuItem value={'Top Left'}>Top Left</MenuItem>
                        <MenuItem value={'Bottom Left'}>Bottom Left</MenuItem>
                        <MenuItem value={'Bottom Right'}>Bottom Right</MenuItem>
                      </Select>
                    </FormControl>
                  )} */}
                </div>
              </div>
            );
          } else {
            if (label === 'font') {
              return (
                <div className={styles.brandKitItem} key={index}>
                  <div className={styles.brandKitItemLeft}>
                    <div className={styles.brandKitItemElem}>
                      <div className={styles.brandKitItemTitle}>Primary Font</div>
                      <div className={styles.brandKitItemText}>{getDescription(label)}</div>
                    </div>
                  </div>
                  <div
                    className={styles.brandKitItemRight}
                    style={{
                      opacity:
                        state.length === 0 && userState && userState.trialDays === 0 ? '0.5' : '10',
                    }}
                    onClick={(event) => {
                      if (state.length === 0 && userState && userState.trialDays === 0) {
                        event.preventDefault();
                        navigate('/subscription/plans');
                      }
                    }}
                  >
                    <div className={styles.fontStyling}>
                      <FontPicker
                        families={[
                          'Arimo',
                          'Barlow',
                          'DM Sans',
                          'Dosis',
                          'IBM Plex Sans',
                          'Inconsolata',
                          'Inter',
                          'Kanit',
                          'Karla',
                          'Lora',
                          'Noto Sans',
                          'Open Sans',
                          'Poppins',
                          'PT Sans',
                          'Raleway',
                          'Roboto',
                          'Titillium Web',
                        ]}
                        apiKey="AIzaSyCbKCvln87bV6cUuP87TG2hm6-sL4SF-d8"
                        activeFontFamily={brandKit.font ?? activeFontFamily}
                        onChange={async (nextFont) => {
                          setActiveFontFamily(nextFont.family);
                          await uploadFont(nextFont.family);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={styles.brandKitItem} key={index}>
                  <div className={styles.brandKitItemLeft}>
                    <div className={styles.brandKitItemElem}>
                      <div className={styles.brandKitItemTitle}>Color</div>
                      <div className={styles.brandKitItemText}>{getDescription(label)}</div>
                    </div>
                  </div>
                  <div
                    className={styles.brandKitItemRight}
                    style={{
                      opacity:
                        state.length === 0 && userState && userState.trialDays === 0 ? '0.5' : '10',
                    }}
                    onClick={(event) => {
                      if (state.length === 0 && userState && userState.trialDays === 0) {
                        event.preventDefault();
                        navigate('/subscription/plans');
                      }
                    }}
                  >
                    <div className={styles.fontStyling}>
                      <div>
                        <input
                          type="color"
                          id="colorpicker"
                          style={{
                            border: 'none',
                            background: 'none',
                            width: '25px',
                            height: '25px',
                          }}
                          value={selectedColor ?? brandKit.color}
                          onChange={handleColorChange}
                        />
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          sx={{ width: '100px', padding: '0 10px' }}
                          value={selectedColor ?? brandKit.color}
                          onChange={handleTextFieldChange}
                        />
                        <img src={colorIcon} alt="color-picker" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default BrandKit;
