import { PeaksInstance } from 'peaks.js';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { FC } from 'react';
import DefaultButton from '@/components/common/DefaultButton';

interface InvalidTCPositionModalProps {
  isOpen: boolean;
  onClose(): void;
  clipOverlap: boolean;
  myPeaks: PeaksInstance;
}

const InvalidTCPositionModal: FC<InvalidTCPositionModalProps> = ({
  isOpen,
  onClose,
  clipOverlap,
  myPeaks,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Unable to Add Segment</DialogTitle>
      <DialogContent>
        {clipOverlap
          ? `There is not enough room for your clip. Please choose a gap larger than ${(
              myPeaks.player.getDuration()! * 0.03
            ).toFixed(1)} seconds.`
          : 'A clip already exists at that position, clips cannot overlap. Please choose an empty gap on the timeline.'}
      </DialogContent>

      <DialogActions>
        <DefaultButton onClick={onClose}>Close</DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default InvalidTCPositionModal;
