import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ProjectContext } from '@/contexts/ProjectContext';
import { VideoContext } from '@/contexts/VideoContext';
import { useSnackbar } from 'notistack';
import { useUploadVideoToWowza } from '@/adapters/projects';

interface WowzaUploadDialogProps {
  open: boolean;
  onClose(): void;
}

const WowzaUploadDialog: FC<WowzaUploadDialogProps> = ({ open, onClose }) => {
  const [fileName, setFileName] = useState<string>('');
  const { project } = useContext(ProjectContext);
  const { video } = useContext(VideoContext);
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    mutateAsync: uploadToWowza,
    isLoading: isUploadingToWowza,
    reset,
  } = useUploadVideoToWowza();

  const wowzaAssetUrl = data?.wowzaAssetUrl;

  useEffect(() => {
    if (!open) {
      reset();
    } else if (project && video) {
      setFileName(`Edit on the Spot - ${project.name}.mp4`);
    }
  }, [open, reset, project, video]);

  const upload = useCallback(async () => {
    if (!project || !video || !fileName.trim()) {
      return;
    }
    try {
      await uploadToWowza({
        id: project.id,
        videoId: video.id,
        fileName: fileName.trim(),
      });
      enqueueSnackbar('Video upload to Wowza has started', {
        variant: 'info',
      });
    } catch (e) {
      //
    }
  }, [project, video, fileName, uploadToWowza, enqueueSnackbar]);

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontSize: 16 }}>Upload to Wowza</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {!isUploadingToWowza && !wowzaAssetUrl && (
            <TextField
              variant="outlined"
              error={!fileName.trim()}
              placeholder={'File name'}
              value={fileName}
              onChange={(event) => setFileName(event.target.value)}
              fullWidth
              size="small"
            ></TextField>
          )}
          {isUploadingToWowza && <CircularProgress />}
          {wowzaAssetUrl && (
            <Alert variant="outlined" severity="success">
              File upload to Wowza has started. Use this{' '}
              <a href={wowzaAssetUrl} rel="noreferrer" target="_blank">
                link {wowzaAssetUrl}
              </a>{' '}
              to access your Wowza asset.
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {!wowzaAssetUrl && (
          <Button onClick={upload} disabled={isUploadingToWowza}>
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WowzaUploadDialog;
