import React, { FC, useState } from 'react';
import styles from './Integrations.module.scss';
import { SelectChangeEvent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import CloudStorage from './CloudStorage';
// import SocialMedia from './SocialMedia';

const Integrations: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSocialMediaPage, setIsSocialMediaPage] = useState<boolean>(false);

  const handleSearchChange = (e: SelectChangeEvent): void => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <div className={styles.navbar}>
        <h1 className={styles.projectsHeadTitle}>Integrations</h1>
        <div className={styles.searchBar}>
          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search"
          />
          <SearchIcon sx={{ color: '#7a7a7a' }} />
        </div>
      </div>
      <div className={styles.integrationTabs}>
        <Typography
          className={styles.integrationTab + (isSocialMediaPage ? '' : ' ' + styles.activeTab)}
          variant="body2"
          fontWeight={'bold'}
          color={'var(--navy-blue)'}
          onClick={() => setIsSocialMediaPage(false)}
        >
          Cloud Storage
        </Typography>
        {/* <Typography
          className={styles.integrationTab + (isSocialMediaPage ? ' ' + styles.activeTab : ' ')}
          variant="body2"
          fontWeight={'bold'}
          color={'var(--navy-blue)'}
          onClick={() => setIsSocialMediaPage(true)}
        >
          Social Media
        </Typography> */}
      </div>
      {/* Uncomment to enable both tabs */}
      {/* {isSocialMediaPage ? <SocialMedia /> : <CloudStorage />} */}
      <CloudStorage />
    </>
  );
};

export default Integrations;
