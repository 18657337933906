import { Button, ButtonProps } from '@mui/material';
import React, { FC } from 'react';
import { useMediaQuery } from '@mui/material';

export interface BaseButtonProps extends ButtonProps {
  rounded?: boolean;
}

const FONT_SIZE_MAP = {
  small: 11,
  medium: 14,
  large: 15,
};

const HEIGHT_MAP = {
  small: 32,
  medium: 36,
  large: 40,
};

const BaseButton: FC<BaseButtonProps> = ({ children, rounded, size, ...buttonProps }) => {
  const isSmallScreen = useMediaQuery('(max-width:767px)');

  const fontSize = isSmallScreen ? FONT_SIZE_MAP.small : FONT_SIZE_MAP[size || 'medium'];

  return (
    <Button
      sx={{
        borderRadius: rounded ? 5 : 2,
        whiteSpace: 'nowrap',
        fontSize: `${fontSize}px`,
        fontWeight: 600,
        textTransform: 'none',
        paddingLeft: 3,
        paddingRight: 3,
        height: HEIGHT_MAP[size || 'medium'],
      }}
      size={size}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default BaseButton;
