import classnames from 'classnames';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useGetAssets, useUploadAssets, BrandKitAssets } from '@/adapters/assets';
import { FileUpload } from '../FileUpload';
import Loading from '../Loading';
import styles from './Video.module.scss';
import VideoHelper from './VideoHelper';
import { useVideoContext } from '@/components/Video/Video';
import BrandKitVideoItem from '@/components/BrandKitVideoItem';

const GRABBER_WIDTH = 8;

interface ControlProps {
  uploadAssetMethod?(file: File, type: string): void;
  introLink?: string;
  outroLink?: string;
}
const VideoTimelineControl: FC<ControlProps> = ({ uploadAssetMethod, introLink, outroLink }) => {
  const {
    videoDuration,
    videoProgress,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setCurrentTime,
    onMoveGrabber,
  } = useVideoContext();

  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const leftGrabberRef = useRef<HTMLDivElement>(null);
  const rightGrabberRef = useRef<HTMLDivElement>(null);

  const addGrabberMoveHandler = (handleMove: (event: MouseEvent) => void): void => {
    const handleMouseUp = (): void => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMove);
  };

  const handleGrabberMove = (clientX: number, grabberType: string): void => {
    const progressBarContainerElt = progressBarContainerRef.current;
    if (!progressBarContainerElt) return;

    const progressBarContainerRect = progressBarContainerElt.getBoundingClientRect();
    let currentTime = VideoHelper.timeFromCursorPosition(
      clientX,
      progressBarContainerRect.left,
      progressBarContainerRect.width,
      videoDuration,
    );

    if (grabberType === 'left') {
      currentTime = currentTime >= 0 ? currentTime : 0;

      if (currentTime < endTime - 1) {
        onMoveGrabber(currentTime);
        setStartTime(currentTime);
      }
    } else {
      currentTime = currentTime < videoDuration ? currentTime : videoDuration;

      if (currentTime > startTime + 1) {
        onMoveGrabber(currentTime);
        setEndTime(currentTime);
      }
    }
  };

  const handleLeftGrabberMove = (clientX: number): void => {
    handleGrabberMove(clientX, 'left');
  };

  const handleRightGrabberMove = (clientX: number): void => {
    handleGrabberMove(clientX, 'right');
  };

  const handleProgressBarClick = (event: React.MouseEvent<HTMLElement>): void => {
    const progressBarContainerElt = progressBarContainerRef.current;
    if (!progressBarContainerElt) return;

    const progressBarContainerRect = progressBarContainerElt.getBoundingClientRect();
    const currentTime = VideoHelper.timeFromCursorPosition(
      event.clientX,
      progressBarContainerRect.left,
      progressBarContainerRect.width,
      videoDuration,
    );

    if (currentTime >= startTime && currentTime <= endTime) {
      setCurrentTime(currentTime);
    }
  };

  const handleResize = useCallback((): void => {
    const leftGrabberElt = leftGrabberRef.current;
    const rightGrabberElt = rightGrabberRef.current;
    const progressBarContainerElt = progressBarContainerRef.current;

    if (!leftGrabberElt || !rightGrabberElt || !progressBarContainerElt) return;
    const progressBarContainerWidth = progressBarContainerElt.getBoundingClientRect().width;

    leftGrabberElt.style.transform = `translateX(${
      VideoHelper.timeToOffset(progressBarContainerWidth, videoDuration, startTime) - GRABBER_WIDTH
    }px)`;
    rightGrabberElt.style.transform = `translateX(${VideoHelper.timeToOffset(
      progressBarContainerWidth,
      videoDuration,
      endTime,
    )}px)`;
  }, [startTime, endTime, videoDuration]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [startTime, endTime, handleResize]);

  const { data: brandKit, isLoading } = useGetAssets();
  const { mutateAsync: uploadBrandKit } = useUploadAssets();

  const introPath = brandKit?.intro.path;
  const outroPath = brandKit?.outro.path;

  const renderGrabbers = (): JSX.Element => {
    const progressBarContainerElt = progressBarContainerRef.current;
    if (!progressBarContainerElt || !videoDuration) return <></>;
    const progressBarContainerWidth = progressBarContainerElt.getBoundingClientRect().width;

    return (
      <>
        <div
          className={classnames(styles.grabber, styles.grabberStart)}
          style={{
            transform: `translateX(${
              VideoHelper.timeToOffset(progressBarContainerWidth, videoDuration, startTime) -
              GRABBER_WIDTH +
              3
            }px)`,
          }}
          onMouseDown={(event) => {
            event.preventDefault();
            addGrabberMoveHandler((event) => handleLeftGrabberMove(event.clientX));
          }}
          onTouchMove={(event) => {
            event.preventDefault();
            handleLeftGrabberMove(event.touches[0].clientX);
          }}
          ref={leftGrabberRef}
          data-testid="leftGrabber"
        >
          <div className={styles.grabberTimeContainer}>
            {VideoHelper.formatTime(startTime * 1000)}
          </div>
        </div>
        <div
          className={classnames(styles.grabber, styles.grabberEnd)}
          style={{
            transform: `translateX(${VideoHelper.timeToOffset(
              progressBarContainerWidth,
              videoDuration,
              endTime,
            )}px)`,
          }}
          onMouseDown={(event) => {
            event.preventDefault();
            addGrabberMoveHandler((event) => handleRightGrabberMove(event.clientX));
          }}
          onTouchMove={(event) => {
            event.preventDefault();
            handleRightGrabberMove(event.touches[0].clientX);
          }}
          ref={rightGrabberRef}
          data-testid="rightGrabber"
        >
          <div className={styles.grabberTimeContainer}>
            {VideoHelper.formatTime(endTime * 1000)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.controls}>
      <div className={styles.pathContainer}>
        {isLoading ? (
          <Loading />
        ) : !introPath && !introLink ? (
          <FileUpload
            styleEl="button"
            fileTypes={['.png', '.mp4']}
            onChange={(file: File) => {
              if (uploadAssetMethod) {
                uploadAssetMethod(file, 'intro');
              } else {
                uploadBrandKit({ type: BrandKitAssets.intro, file })
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              }
            }}
          />
        ) : introLink ? (
          // <Link to={BRAND_KIT_URL}>
          <>
            <input
              type="file"
              name="myImage"
              accept=".png, .jpeg"
              id="fileInputIntro"
              style={{ display: 'none' }}
              onChange={(event) => {
                if (event.target.files) {
                  if (uploadAssetMethod) {
                    uploadAssetMethod(event.target.files[0], 'intro');
                  } else {
                    uploadBrandKit({
                      type: BrandKitAssets.intro,
                      file: event.target.files[0],
                    })
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err));
                  }
                }
              }}
            />
            <label
              htmlFor="fileInputIntro"
              style={{
                cursor: 'pointer',
                padding: '10px',
                border: '0px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <BrandKitVideoItem path={introLink} />
            </label>
          </>
        ) : // </Link>
        introPath ? (
          // <Link to={BRAND_KIT_URL}>
          <>
            <input
              type="file"
              name="myImage"
              accept=".png, .jpeg"
              id="fileInputIntro"
              style={{ display: 'none' }}
              onChange={(event) => {
                if (event.target.files) {
                  if (uploadAssetMethod) {
                    uploadAssetMethod(event.target.files[0], 'intro');
                  } else {
                    uploadBrandKit({
                      type: BrandKitAssets.intro,
                      file: event.target.files[0],
                    })
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err));
                  }
                }
              }}
            />
            <label
              htmlFor="fileInputIntro"
              style={{
                cursor: 'pointer',
                padding: '10px',
                border: '0px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <BrandKitVideoItem path={introPath} />
            </label>
          </>
        ) : (
          // </Link>
          <></>
        )}
      </div>

      <div className={styles.progressBarWrapper} data-testid="videoProgressBarWrapper">
        <div className={styles.progressBarLine} />
        {renderGrabbers()}
        <div className={styles.progressBarContainer} ref={progressBarContainerRef} />
        <div
          className={styles.progressSegment}
          onClick={handleProgressBarClick}
          style={{
            left: `${(startTime / videoDuration) * 100}%`,
            width: `${((endTime - startTime) / videoDuration) * 100}%`,
          }}
        />
        <div
          className={styles.progressBar}
          data-testid="videoProgressBar"
          style={{ width: `${videoProgress}%`, left: `${(startTime / videoDuration) * 100}%` }}
        ></div>
      </div>

      <div className={styles.pathContainer}>
        {isLoading ? (
          <Loading />
        ) : !outroPath && !outroLink ? (
          <FileUpload
            styleEl="button"
            fileTypes={['.png', '.mp4']}
            onChange={(file: File) => {
              if (uploadAssetMethod) {
                uploadAssetMethod(file, 'outro');
              } else {
                uploadBrandKit({ type: BrandKitAssets.outro, file })
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              }
            }}
          />
        ) : outroLink ? (
          // <Link to={BRAND_KIT_URL}>
          <>
            <input
              type="file"
              name="myImage"
              accept=".png, .jpeg"
              id="fileInputOutro"
              style={{ display: 'none' }}
              onChange={(event) => {
                if (event.target.files) {
                  if (uploadAssetMethod) {
                    uploadAssetMethod(event.target.files[0], 'outro');
                  } else {
                    uploadBrandKit({ type: BrandKitAssets.outro, file: event.target.files[0] })
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err));
                  }
                }
              }}
            />
            <label
              htmlFor="fileInputOutro"
              style={{
                cursor: 'pointer',
                padding: '10px',
                border: '0px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <BrandKitVideoItem path={outroLink} />
            </label>
          </>
        ) : // </Link>
        outroPath ? (
          // <Link to={BRAND_KIT_URL}>
          <>
            <input
              type="file"
              name="myImage"
              accept=".png, .jpeg"
              id="fileInputOutro"
              style={{ display: 'none' }}
              onChange={(event) => {
                if (event.target.files) {
                  if (uploadAssetMethod) {
                    uploadAssetMethod(event.target.files[0], 'outro');
                  } else {
                    uploadBrandKit({ type: BrandKitAssets.outro, file: event.target.files[0] })
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err));
                  }
                }
              }}
            />
            <label
              htmlFor="fileInputOutro"
              style={{
                cursor: 'pointer',
                padding: '10px',
                border: '0px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <BrandKitVideoItem path={outroPath} />
            </label>
          </>
        ) : (
          // </Link>
          <></>
        )}
      </div>
    </div>
  );
};

export default VideoTimelineControl;
