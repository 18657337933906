import { FC } from 'react';
import styles from './Users.module.scss';

/* istanbul ignore file */

interface Props {
  text: string;
}

const NoUsers: FC<Props> = ({ text }) => {
  return (
    <div className={styles.noProjects}>
      <div className={styles.noProjectsItem}>{text}</div>
    </div>
  );
};

export default NoUsers;
