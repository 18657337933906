import useAuthorization from '@/hooks/useAuthorization';
import UserForm from '@/pages/UserForm';
import Users from '@/pages/Users';
import { Route } from 'react-router-dom';
import * as urls from '../routePaths';

const UsersRoutes: any = () => {
  const { hasRole } = useAuthorization();

  return hasRole('super_admin')
    ? [
        <Route path={urls.USERS_URL} element={<Users />} key="users" />,
        <Route path={urls.CREATE_USER_URL} element={<UserForm />} key="create-user" />,
        <Route path={urls.UPDATE_USER_URL} element={<UserForm />} key="update-user" />,
      ]
    : [];
};

export default UsersRoutes;
