import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FC } from 'react';
import Loading from '../Loading';

/* istanbul ignore file */

const onRedirecting = (): JSX.Element => <Loading />;

const ProtectedArea: FC = withAuthenticationRequired(
  ({ children }) => {
    return <>{children}</>;
  },
  { onRedirecting },
);

export default ProtectedArea;
