import { FC } from 'react';
import styles from './Projects.module.scss';

/* istanbul ignore file */

interface Props {
  text: JSX.Element;
  media: JSX.Element;
  link?: string;
  linkText?: string;
}

const NoProjects: FC<Props> = ({ text, media, link, linkText }) => {
  return (
    <div className={styles.noProjects}>
      <div className={styles.noProjectsItem}>
        <div className={styles.noProjectsMedia}>{media}</div>
        <div className={styles.noProjectsText}>{text}</div>
        {linkText && (
          <a href={link} className={styles.noProjectsLink} target="_blank" rel="noreferrer">
            {linkText}
          </a>
        )}
      </div>
    </div>
  );
};

export default NoProjects;
