import { FC } from 'react';
import styles from './Video.module.scss';
// import Timing from '../../entities/timing';
import classnames from 'classnames';
import ToggleSwitch from '../ToggleSwitch';
import { useVideoContext } from '@/components/Video/Video';

interface Props {
  onCancel(): void;

  onSave(): Promise<void>;

  isSaving: boolean;

  onPlayPause(): void;

  onToggle(): void;
}

const VideoEditor: FC<Props> = ({ onCancel, onSave, isSaving, onPlayPause, onToggle }) => {
  const { playing } = useVideoContext();
  const isEnhanceVideoEnabled = false;

  return (
    <div className={classnames(styles.buttonsContainer, { [styles.saving]: isSaving })}>
      <div className={styles.firstButtonGroup}>
        {isEnhanceVideoEnabled && (
          <div className={styles.videoActionsToggle}>
            <ToggleSwitch label="Enhance Video" onClick={onToggle} testId={'test-enhance'} />
          </div>
        )}
      </div>
      <div className={styles.secondButtonGroup}>
        <button
          onClick={onPlayPause}
          data-testid="playButton"
          data-isplaying={playing}
          disabled={isSaving}
          className={styles.playWrapper}
        >
          <span
            className={classnames(styles.playButton, {
              [styles.isPlaying]: playing,
            })}
          ></span>
          <span className={styles.playButtonText}>Play</span>
        </button>
        <div className={styles.box}>
          <button onClick={onCancel} disabled={isSaving} className={styles.cancelButton}>
            Cancel
          </button>
          <button onClick={onSave} disabled={isSaving} className={styles.saveChangesButton}>
            {isSaving ? 'Saving Changes' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoEditor;
