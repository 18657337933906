import React, { FC, useState } from 'react';
import styles from './AppLayout.module.scss';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '@/components/Loading';

/* istanbul ignore file */

const onRedirecting = (): JSX.Element => <Loading />;

const AppLayout: FC = withAuthenticationRequired(
  ({ children }) => {
    const [isOpen, setOpen] = useState(true);

    const toggleSidebar = (): void => {
      setOpen(!isOpen);
    };

    return (
      <div className={styles.layout}>
        <Sidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
        <main className={isOpen ? styles.mainContent : styles.mainContentClosed}>
          <Header />
          <section className={styles.content}>
            <Outlet />
          </section>
        </main>
      </div>
    );
  },
  {
    onRedirecting,
  },
);

export default AppLayout;
