import { TestSegmentProps } from '../types';

export const createNewSegment = (
  segments: TestSegmentProps[],
  playheadPosition: number,
  mediaLength: number,
): TestSegmentProps => {
  return {
    id: segments.length.toString(),
    fileName: `Segment-${parseInt(segments.length.toString()) + 1}`,
    startTime: playheadPosition,
    endTime: playheadPosition + mediaLength * 0.03,
    editable: false,
    color: '#1E1541',
    labelText: `Segment-${parseInt(segments.length.toString()) + 1}`,
    formErrors: {
      fileNameError: false,
      isCreated: false,
    },
    status: 'Staging',
  };
};

export const formatAxisTime = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const reFormatAxisTime = (timeString: string): number => {
  const [hoursStr = '0', minutesStr = '0', secondsStr = '0'] = timeString.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);
  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

  return totalTimeInSeconds;
};
