import { Route } from 'react-router-dom';
import * as urls from '../routePaths';
import Projects from '../pages/Projects';
import Project from '../pages/Project';
import ProjectVideo from '../pages/ProjectVideo';
import ProjectVideoTranscript from '../pages/ProjectVideoTranscript';

const ProjectsRoutes = [
  <Route path={urls.PROJECTS_URL} element={<Projects />} key="projects" />,
  <Route path={urls.PROJECT_URL} element={<Project />} key="project" />,
  <Route path={urls.PROJECT_VIDEO_URL} element={<ProjectVideo />} key="project-video" />,
  // <Route
  //   path={urls.PROJECT_TRANSCRIPT_URL}
  //   element={<ProjectVideoTranscript />}
  //   key="project-transcript"
  // />,
  <Route
    path={urls.PROJECT_TRANSCRIPT_URL}
    element={<ProjectVideoTranscript />}
    key="project-transcript"
  />,
  <Route
    path={urls.PROJECT_VIDEO_TRANSCRIPT_URL}
    element={<ProjectVideoTranscript />}
    key="project-video-transcript"
  />,
];

export default ProjectsRoutes;
