import { FC } from 'react';

/* istanbul ignore file */

interface Props {
  className?: string;
}

const LogoutIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 385 385"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_444_132)">
        <path
          d="M180.455 360.91H24.061V24.061H180.455C187.096 24.061 192.485 18.671 192.485 12.031C192.485 5.39098 187.095 0.000976562 180.455 0.000976562H12.03C5.39 0.000976562 0 5.38998 0 12.031V372.94C0 379.581 5.39 384.97 12.03 384.97H180.454C187.095 384.97 192.484 379.58 192.484 372.94C192.485 366.299 187.095 360.91 180.455 360.91Z"
          fill="black"
        />
        <path
          d="M381.481 184.088L298.472 99.888C293.768 95.136 286.153 95.148 281.461 99.888C276.757 104.628 276.757 112.327 281.461 117.067L344.019 180.527H96.279C89.638 180.527 84.249 185.965 84.249 192.678C84.249 199.391 89.639 204.829 96.279 204.829H344.019L281.461 268.289C276.757 273.041 276.757 280.728 281.461 285.468C286.165 290.22 293.78 290.22 298.472 285.468L381.469 201.268C386.113 196.588 386.161 188.756 381.481 184.088Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_444_132">
          <rect width="384.971" height="384.971" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
