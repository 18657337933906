import { FC } from 'react';
import spinner from '../../assets/images/spinner.svg';
import styles from './Loading.module.scss';

/* istanbul ignore file */

interface Props {
  text?: string;
}

const Loading: FC<Props> = ({ text }) => {
  return (
    <div className={styles.container}>
      <img src={spinner} className={styles.spinner} data-testid="loading" alt="" />
      {text && <div>{text}</div>}
    </div>
  );
};

export default Loading;
