import { FC } from 'react';
import styled from '@emotion/styled';
import { getExtensionFromSignedUrl } from '@/utils';

export interface AssetInterface {
  path: string;
}

const StyledVideo = styled.video`
  max-width: 100%;
`;

const StyledImage = styled.img`
  max-width: 100%;
`;

const BrandKitVideoItem: FC<{ path: string; controls?: boolean }> = ({ path, controls }) => {
  const extension = path && getExtensionFromSignedUrl(path);

  return (
    <>
      {extension === 'mp4' ? (
        <StyledVideo controls={controls} playsInline>
          <source src={path} type="video/mp4" />
        </StyledVideo>
      ) : (
        <StyledImage src={path} alt="Brand Kit Asset" />
      )}
    </>
  );
};

export default BrandKitVideoItem;
