import { FC, useState, useEffect, FormEventHandler, useContext, useMemo } from 'react';
import styles from './StreamKeyCheckoutModal.module.scss';
// import './Checkout.scss';
import Modal from '@mui/material/Modal';
import { Typography, Grid } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PrimaryButton from '@/components/common/PrimaryButton';
import {
  useCreateStreamSubscription,
  usePaymentMethod,
  useUpdateStreamSubscription,
} from '@/adapters/subscription';
import { useAuth0 } from '@auth0/auth0-react';
import { SUBSCRIPTION_HISTORY_URL } from '@/routePaths';
import { UserContext } from '@/contexts/UserContext';
import { GetPaymentMethodResponse, UserSubscriptionState } from '@/entities/subscription';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}
/* istanbul ignore file */
interface Props {
  open: boolean;
  isAnnualModeInit: boolean;
  handleClose(): void;
  setIsAnnualMode(value: boolean): void;
  updateSubscription(count: number): Promise<void>;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  minWidth: 'auto',
  maxWidth: '1200px',
  maxHeight: '95vh',
  overflow: 'auto',
  bgcolor: 'var(--white)',
  borderRadius: '34px',
  '@media (max-width: 600px)': {
    position: 'absolute',
    bottom: '0',
    top: '55%',
    height: '90vh',
    width: '100%',
    borderRadius: '0',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
};

const CheckoutForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          window.location.protocol + '//' + window.location.host + SUBSCRIPTION_HISTORY_URL
        }`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      console.log(error.message ?? '');
    } else {
      console.log('An unexpected error occurred.');
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <PrimaryButton className={styles.submitButton} type="submit">
        <Typography className={styles.submitButtonLabel}>Purchase</Typography>
      </PrimaryButton>
    </form>
  );
};

const StreamKeyCheckoutModal: FC<Props> = ({
  open,
  isAnnualModeInit,
  handleClose,
  setIsAnnualMode,
  updateSubscription,
}) => {
  // const [isAnnualMode, setIsAnnualMode] = useState<boolean>(isAnnualModeInit);
  const [isFaqExpand, setIsFaqExpand] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const { userState } = useContext(UserContext);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

  const { user } = useAuth0();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const email = user?.email;
  const { mutateAsync: createStreamSubscription } = useCreateStreamSubscription();
  const [upgrade, setUpgrade] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);
  const [streamKeyState, setStreamKeyState] = useState<UserSubscriptionState[] | null>(null);

  const { data: paymentMethodResponse }: { data: GetPaymentMethodResponse | undefined } =
    usePaymentMethod();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');

  const handlePaymentMethodChange = (event: SelectChangeEvent): void => {
    setSelectedPaymentMethod(event.target.value);
  };

  const calculateNextBillingDate = (): string => {
    if (Array.isArray(streamKeyState) && streamKeyState.length > 0) {
      if (streamKeyState[0].next_billing_date !== 0) {
        // Use the existing next_billing_date if it's not 0
        return new Date(streamKeyState[0].next_billing_date * 1000).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      } else {
        // Calculate next_billing_date based on the chosen billing mode
        const isAnnualMode = isAnnualModeInit; // Assuming isAnnualModeInit is a boolean representing the billing mode

        // Calculate the next_billing_date based on the chosen billing mode
        const now = new Date();
        const nextBillingDate = isAnnualMode
          ? new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()) // Add one year for annual billing
          : new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()); // Add one month for monthly billing

        return (
          ' ' +
          nextBillingDate.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
        );
      }
    }
    return '';
  };

  useEffect(() => {
    if (userState) {
      setStreamKeyState(
        userState.SubscriptionState.length > 0
          ? userState.SubscriptionState.filter(
              (state) =>
                state.plan === process.env.REACT_APP_STRIPE_RTMP_PLAN_MONTHLY ||
                state.plan === process.env.REACT_APP_STRIPE_RTMP_PLAN_ANNUAL,
            )
          : [],
      );
    }
  }, [userState]);

  const handleCreate: () => Promise<void> = async () => {
    if (email) {
      setConfirmed(true);
      setIsLoading(true);
      const { clientSecret }: { clientSecret: string } = await createStreamSubscription({
        email: email,
        quantity: count,
        lookup_key:
          (isAnnualModeInit
            ? process.env.REACT_APP_STRIPE_RTMP_PLAN_ANNUAL
            : process.env.REACT_APP_STRIPE_RTMP_PLAN_MONTHLY) || '',
      });
      setIsLoading(false);
      setClientSecret(clientSecret);
    }
  };

  useEffect(() => {
    if (email && open && streamKeyState !== null) {
      // Create PaymentIntent as soon as the page loads
      if (streamKeyState.length > 0) {
        setUpgrade(true);
        // setIsLoading(true);
        // const createConfig: () => Promise<void> = async () => {

        // };

        // han()
        //   .then(() => {
        //     console.log('success');
        //   })
        //   .catch(() => {
        //     console.log('failed');
        //   });
      }
      // else if(streamKeyState[0].plan === process.env.REACT_APP_STRIPE_RTMP_PLAN_MONTHLY && !isAnnualModeInit)
      // {
      //   setUpgrade(false)
      //   setIsAnnualMode(false)
      //   setAlreadySubscribed(true)
      // }
      // else {
      //   // isAnnualModeInit ? setIsAnnualMode(true) : setIsAnnualMode(false);
      //   setUpgrade(true);
      // }
    }
  }, [email, isAnnualModeInit, createStreamSubscription, open, streamKeyState, count]);

  console.log(clientSecret);

  const { mutateAsync: updateStreamSubscription } = useUpdateStreamSubscription();
  const handleUpdateSubscription = async (count: number): Promise<void> => {
    if (email && paymentMethodResponse) {
      const card = paymentMethodResponse.payment_methods.filter(
        (_) => _.id === selectedPaymentMethod,
      );
      setLoading(true);
      const { updated }: { updated: string } = await updateStreamSubscription({
        email: email,
        lookup_key: isAnnualModeInit
          ? process.env.REACT_APP_STRIPE_RTMP_PLAN_MONTHLY
          : process.env.REACT_APP_STRIPE_RTMP_PLAN_ANNUAL,
        quantity: count,
        paymentMethodId: card[0]?.id,
      });

      if (updated) {
        window.location.reload();
        // navigate(MAIN_URL);
      }
    }
  };

  const totalPrice = useMemo(() => {
    if (isAnnualModeInit) {
      return '$' + (count * 480).toFixed(2);
    } else {
      return '$' + (count * 49.95).toFixed(2);
    }
  }, [count, isAnnualModeInit]);

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose} disableScrollLock>
      <Box sx={{ ...style, minWidth: 'auto', maxHeight: '95vh', overflow: 'auto' }}>
        <div className={styles.closeMark} onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </div>
        <Grid container className={styles.gridContainer}>
          <Grid item xs={12} md={6} className={styles.orderSummary}>
            <Typography className={styles.planTitle}>PRO</Typography>
            <Typography className={styles.planDescription}>Keep all your videos forever</Typography>
            <Typography className={styles.orderTitle}>Order Summary</Typography>
            <div className={styles.paymentModeSwitch}>
              <div className={styles.paymentModes}>
                <div
                  className={styles.paymentMode + (isAnnualModeInit ? '' : ' ' + styles.activeMode)}
                  onClick={() => {
                    setIsAnnualMode(false);
                    setCount(1);
                  }}
                >
                  Monthly
                </div>
                <div
                  className={styles.paymentMode + (isAnnualModeInit ? ' ' + styles.activeMode : '')}
                  onClick={() => {
                    setIsAnnualMode(true);
                    setCount(1);
                  }}
                >
                  Annually
                </div>
              </div>
              <div className={styles.annualDiscount}>Save 20%</div>
            </div>
            <div className={styles.invoice}>
              <div className={styles.priceTable}>
                <div className={styles.tableHeader}>
                  <Typography className={styles.tableItem}>Product/Service</Typography>
                  <Typography className={styles.tableItem}>Price</Typography>
                </div>
                <div className={styles.tableBody}>
                  <div className={styles.productDescription}>
                    <div className={styles.closeIcon}>
                      <CloseIcon />
                    </div>
                    <div className={styles.productContent}>
                      <Typography className={styles.productTop}>Additional Stream Key</Typography>
                      <Typography className={styles.productBottom}>
                        EditOnTheSpot Multiple Stream
                      </Typography>
                    </div>
                  </div>
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div
                      className={styles.minusContainer}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (count > 1) {
                          setCount(count - 1);
                          setClientSecret('');
                          setConfirmed(false);
                        }
                      }}
                    >
                      -
                    </div>
                    <div className={styles.count}>{count}</div>
                    <div
                      className={styles.minusContainer}
                      onClick={() => {
                        setCount(count + 1);
                        setClientSecret('');
                        setConfirmed(false);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      +
                    </div>
                  </div>
                  <Typography className={styles.price}>
                    {isAnnualModeInit ? '$480' : '$49.95'}
                  </Typography>
                </div>
                <div className={styles.horizonLine}></div>
              </div>
              <div className={styles.totalPrice}>
                {streamKeyState && streamKeyState.length > 0 && (
                  <div className={styles.totalPriceTop}>
                    <Typography className={styles.tableItem}>Total:</Typography>
                    <Typography className={styles.tableItem}>{totalPrice}</Typography>
                  </div>
                )}
                {Array.isArray(streamKeyState) &&
                  streamKeyState.length > 0 &&
                  streamKeyState[0].adjustments &&
                  isAnnualModeInit && (
                    <>
                      <div className={styles.totalPriceTop}>
                        <Typography className={styles.renewInfo}>Used Amount:</Typography>
                        <Typography className={styles.renewInfo}>
                          {'$' + streamKeyState[0].adjustments.usedAmount + ' USD'}
                        </Typography>
                      </div>
                      <div className={styles.totalPriceTop}>
                        <Typography className={styles.renewInfo}>Prorated Amount:</Typography>
                        <Typography className={styles.renewInfo}>
                          {'$' + streamKeyState[0].adjustments.proratedAmount + ' USD'}
                        </Typography>
                      </div>
                      <div className={styles.totalPriceTop}>
                        <Typography className={styles.tableItem}>Total for Upgrade:</Typography>
                        <Typography className={styles.tableItem}>
                          {'$' + streamKeyState[0].adjustments.upgradeTotal + ' USD'}
                        </Typography>
                      </div>
                    </>
                  )}
                <div className={styles.totalPriceBottom}>
                  <Typography className={styles.renewInfo}>
                    Subscription renews on
                    <span className={styles.renewDate}>{' ' + calculateNextBillingDate()}</span>
                  </Typography>
                  <Typography className={styles.totalPriceUnit}>USD</Typography>
                </div>
                <div className={styles.horizonLine}></div>
              </div>
              <div className={styles.email}>
                <Typography className={styles.inputLabel}>Email Address</Typography>
                <input className={styles.emailInput} placeholder={email} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={styles.paymentDetails}>
            <Typography className={styles.paymentDetailTitle}>Payment Details</Typography>
            <div className={styles.paymentElement}>
              {isLoading ? (
                <div className={styles.faqLabel} style={{ textAlign: 'center' }}>
                  Loading ...
                </div>
              ) : (
                <>
                  {clientSecret && confirmed && (
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret: clientSecret,
                        // mode: 'payment',
                        // currency: 'usd',
                        // amount: 1099,
                        appearance: {
                          variables: {
                            fontSizeBase: '13px',
                            borderRadius: '6px',
                            fontWeightNormal: '500',
                            colorText: '#2C3277',
                            colorTextPlaceholder: '#A7A7A7',
                          },
                          rules: {
                            '.Input': {
                              border: '2px #000 solid',
                              boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.07)',
                            },
                          },
                        },
                      }}
                    >
                      <CheckoutForm />
                    </Elements>
                  )}
                  {!upgrade && !confirmed && (
                    <PrimaryButton
                      className={styles.submitButton}
                      onClick={async () => await handleCreate()}
                    >
                      <Typography className={styles.submitButtonLabel}>Confirm Quantity</Typography>
                    </PrimaryButton>
                  )}

                  {upgrade && (
                    <>
                      <Typography className={styles.paymentList}>
                        Payment Method List
                        {/* <span className={styles.renewDate}>{calculateNextBillingDate()}</span> */}
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Payment Method"
                        style={{ width: '100%', flex: 1, display: 'flex' }}
                        value={
                          selectedPaymentMethod.length > 0
                            ? selectedPaymentMethod
                            : 'Please select payment method'
                        }
                        onChange={handlePaymentMethodChange}
                        sx={{ height: '40px', borderRadius: '8px' }}
                        // onChange={handleChange}
                      >
                        <MenuItem key={'index'} value={'Please select payment method'} disabled>
                          Please select payment method
                        </MenuItem>
                        {paymentMethodResponse &&
                          paymentMethodResponse.payment_methods.length > 0 &&
                          paymentMethodResponse.payment_methods.map((card, index) => {
                            return (
                              <MenuItem key={index} value={card.id}>
                                {'xxxx xxxx xxxx ' + card.card.last4}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </>
                  )}
                  {upgrade && (
                    <PrimaryButton
                      className={styles.submitButton}
                      type="submit"
                      disabled={selectedPaymentMethod === ''}
                      onClick={async () => await handleUpdateSubscription(count)}
                    >
                      <Typography className={styles.submitButtonLabel}>
                        {!loading ? 'Upgrade' : 'Processing'}
                      </Typography>
                    </PrimaryButton>
                  )}
                </>
              )}
              {/* {(alreadySubscribed) && (
                  <div className={styles.faqLabel} style={{ color: 'red', textAlign: 'center' }}>
                    You are already subscribed
                  </div>
                )} */}
            </div>
            <div className={styles.faq}>
              <div className={styles.faqLabel} onClick={() => setIsFaqExpand(!isFaqExpand)}>
                Frequently Asked Questions
                {isFaqExpand ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
              </div>
              {isFaqExpand && (
                <div className={styles.faqContent}>
                  <Typography className={styles.faqQuestion}>
                    How is pricing calculated for the Additional Stream Key?
                  </Typography>
                  <Typography className={styles.faqAnswer}>
                    Pricing is calculated per key. Pay annually $480 USD per year (20%). Pay monthly
                    is $49.95 USD per month.
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default StreamKeyCheckoutModal;
