import React, { FC, useCallback, useRef } from 'react';
import { BaseButtonProps } from '@/components/common/BaseButton/BaseButton';
import PrimaryButton from '@/components/common/PrimaryButton';

interface FileButtonProps extends BaseButtonProps {
  onFileChange(file: File): Promise<void>;
}

const FileButton: FC<FileButtonProps> = ({ children, onFileChange, ...buttonProps }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        await onFileChange(file);
        target.value = '';
      }
    },
    [onFileChange],
  );

  return (
    <PrimaryButton
      {...buttonProps}
      disabled={false}
      disableRipple={buttonProps.disabled}
      onClick={() => inputFileRef.current?.click()}
    >
      {children}
      <input
        type="file"
        accept=".mp4"
        style={{ display: 'none' }}
        ref={inputFileRef}
        disabled={buttonProps.disabled}
        onChange={onChange}
      />
    </PrimaryButton>
  );
};

export default FileButton;
