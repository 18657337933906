import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Transcript, TranscriptItem } from '@/types/transcript';
import { toJson, toTxt, toVtt2 as toVtt } from '@/utils/transcript';
import { downloadFile } from '@/utils';
import { useGetProjectVideoTranscript, useUpdateProjectVideoTranscript } from '@/adapters/projects';
import Project from '@/types/project';
export interface TranscriptContextValue {
  transcript?: Transcript | null;
  currentTime?: number | null;
  editable: boolean;
  onSelectItem(item: TranscriptItem): void;
  onUpdateItem(item: TranscriptItem, text: string): void;
  edit(): void;
  save(): void;
  cancel(): void;
  undo(): void;
  redo(): void;
  exportToTXT(): void;
  exportToJSON(): void;
  exportToVTT(): void;
  isLoadingTranscript: boolean;
  isUpdatingProjectVideoTranscript: boolean;
  transcriptContainerRef: any;
  timeCodes: {
    start: number;
    end: number;
  } | null;
}
export const TranscriptContext = React.createContext<TranscriptContextValue>({
  transcript: null,
  editable: false,
  onSelectItem: () => undefined,
  onUpdateItem: () => undefined,
  edit: () => undefined,
  save: () => undefined,
  cancel: () => undefined,
  undo: () => undefined,
  redo: () => undefined,
  exportToTXT: () => undefined,
  exportToJSON: () => undefined,
  exportToVTT: () => undefined,
  isLoadingTranscript: false,
  isUpdatingProjectVideoTranscript: false,
  transcriptContainerRef: null,
  timeCodes: null,
});
export interface TranscriptProviderProps {
  children: React.ReactNode;
  project: Project;
  videoId: string;
  videoRef?: React.RefObject<HTMLVideoElement>;
  currentTime?: number | null;
  setTimeStamp?(timeStamp: number): void;
  containerRef: React.RefObject<HTMLDivElement>;
  seg?: boolean;
  timeCodes?: {
    start: number;
    end: number;
  };
}
export const TranscriptProvider: React.FC<TranscriptProviderProps> = ({
  children,
  project,
  videoId,
  videoRef,
  currentTime,
  setTimeStamp,
  containerRef,
  seg,
  timeCodes,
}: TranscriptProviderProps) => {
  const { data: transcript, isLoading: isLoadingTranscript } = useGetProjectVideoTranscript(
    project.id,
    videoId,
  );
  const { mutateAsync: updateProjectVideoTranscript, isLoading: isUpdatingProjectVideoTranscript } =
    useUpdateProjectVideoTranscript(project.id, videoId);
  const [editableTranscript, setEditableTranscript] = useState<Transcript | undefined>();
  const transcriptItemMapRef = useRef<Record<number, TranscriptItem>>({});
  const [editable, setEditable] = useState<boolean>(false);
  useEffect(() => {
    if (transcript) {
      transcriptItemMapRef.current = transcript.items.reduce(
        (result: Record<number, TranscriptItem>, item) => {
          if (item.start) {
            result[item.start] = { ...item };
          }
          return result;
        },
        {},
      );
      setEditableTranscript({
        transcript: transcript.transcript,
        items: transcript.items.map((item) => ({ ...item })),
      });
    }
  }, [transcript, transcriptItemMapRef]);
  const edit = useCallback(() => {
    setEditable(true);
  }, []);
  const save = useCallback(async () => {
    const items = Object.values(transcriptItemMapRef.current).filter((item) => !!item.text.trim());
    items.sort(function (a, b) {
      return a.start - b.start;
    });
    await updateProjectVideoTranscript({
      transcript: items.map((item) => item.text.trim()).join(' '),
      items,
    });
    setEditable(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [updateProjectVideoTranscript, transcriptItemMapRef]);
  const cancel = useCallback(() => {
    if (transcript) {
      setEditableTranscript({
        transcript: transcript.transcript,
        items: transcript.items.map((item) => ({ ...item })),
      });
    }
    setEditable(false);
  }, [transcript, setEditable]);
  const onSelectItem = useCallback(
    (item: TranscriptItem) => {
      if (videoRef && videoRef.current) {
        videoRef.current.currentTime = item.start;
        setTimeStamp && setTimeStamp(item.start);
      }
    },
    [videoRef],
  );
  const onUpdateItem = useCallback(
    (item: TranscriptItem, text: string) => {
      console.log('Updated');
      transcriptItemMapRef.current[item.start].text = text.trim();
    },
    [transcriptItemMapRef],
  );
  const undo = useCallback(() => {
    //
  }, []);
  const redo = useCallback(() => {
    //
  }, []);
  const exportToTXT = (): void => {
    if (!project || !transcript) {
      return;
    }
    const txt = toTxt(transcript);
    downloadFile(txt, `${project.name} - original.txt`);
  };
  const exportToJSON = (): void => {
    if (!project || !transcript) {
      return;
    }
    const txt = toJson(transcript);
    downloadFile(txt, `${project.name} - original.json`);
  };
  const exportToVTT = (): void => {
    if (!project || !transcript) {
      return;
    }
    const vtt = toVtt(transcript);
    downloadFile(vtt, `${project.name} - original.vtt`);
  };
  return (
    <TranscriptContext.Provider
      value={{
        transcript: editableTranscript,
        currentTime,
        editable: editable,
        onSelectItem,
        onUpdateItem,
        edit,
        save,
        cancel,
        undo,
        redo,
        exportToTXT,
        exportToJSON,
        exportToVTT,
        isLoadingTranscript,
        isUpdatingProjectVideoTranscript,
        transcriptContainerRef: containerRef,
        timeCodes: timeCodes ?? null,
      }}
    >
      {children}
    </TranscriptContext.Provider>
  );
};
