import styled from '@emotion/styled';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  button {
    margin-left: 10px;
    margin-top: 10px;
  }
`;
