/* istanbul ignore file */
import { FC, PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { PROJECTS_URL, SUBSCRIPTION_PLANS_URL } from '../../routePaths';

const AuthProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  let redirectUri = window.location.origin + PROJECTS_URL;

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has('from') && queryParams.get('from') === 'pricing') {
    redirectUri = window.location.origin + SUBSCRIPTION_PLANS_URL;
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="openid email"
      // redirectUri={'https://app.dev.editonthespot.com/projects'}
      redirectUri={redirectUri}
      login_hint={new URLSearchParams(window.location.search).get('email') ?? ''}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
