import React, { FC, MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

interface ShareButtonProps {
  className?: string;
  label: string;
  icon: React.ReactNode;
  loading?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  src?: string;
}

const CircleButton = styled.div`
  background-color: #f4f4f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
`;

const ShareButton: FC<ShareButtonProps> = ({ className, icon, label, loading, onClick, src }) => {
  return (
    <button className={className} onClick={onClick}>
      <CircleButton>
        {loading ? (
          <CircularProgress />
        ) : src ? (
          <img src={src} />
        ) : (
          <CircleButton>{icon}</CircleButton>
        )}
      </CircleButton>
      <label>{label}</label>
    </button>
  );
};

export default styled(ShareButton)`
  ${CircleButton} {
    margin-bottom: 10px;
  }

  background-color: transparent;
  border-width: 0;
  cursor: pointer;
`;
