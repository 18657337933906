import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import styles from './FileUpload.module.scss';

/* istanbul ignore file */

export interface FileUploadProps {
  onChange(file: File): Promise<void> | void;
  fileTypes: string[];
  styleEl: 'panel' | 'button';
}

export const UPLOAD_IS_IN_PROGRESS_TEXT = 'File uploading is in progress...';
export const UPLOAD_AREA_TEXT = 'Click or drag file to this area to upload';
export const UPLOAD_ERROR_TEXT = 'Upload failed. Please check file type and dimensions.';

type State = 'ready' | 'loading' | 'error';

export const FileUpload: FC<FileUploadProps> = ({ onChange, fileTypes, styleEl }) => {
  const [state, setState] = useState<State>('ready');

  const onChangeWrapper = useCallback(
    async (file: File) => {
      try {
        setState('loading');
        await onChange(file);
        setState('ready');
      } catch (e) {
        setState('error');
      }
    },
    [onChange],
  );

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
        await onChangeWrapper(event.dataTransfer.files[0]);
      }
    },
    [onChangeWrapper],
  );

  const onChangeInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        await onChangeWrapper(file);
        target.value = '';
      }
    },
    [onChangeWrapper],
  );

  const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const svgIconDefault = (
    <svg width="15" height="15" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.804 30L30 15.0149L14.804 0L11.397 3.36643L20.7437 12.6316H0V17.3982H20.7437L11.397 26.6336L14.804 30Z"
        fill="var(--catskill-white)"
      />
    </svg>
  );

  const svgIconButton = (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.66683 8.66667H6.3335C6.70016 8.66667 7.00016 8.36667 7.00016 8V4.66667H8.06016C8.6535 4.66667 8.9535 3.94667 8.5335 3.52667L5.4735 0.466667C5.2135 0.206667 4.7935 0.206667 4.5335 0.466667L1.4735 3.52667C1.0535 3.94667 1.34683 4.66667 1.94016 4.66667H3.00016V8C3.00016 8.36667 3.30016 8.66667 3.66683 8.66667ZM1.00016 10H9.00016C9.36683 10 9.66683 10.3 9.66683 10.6667C9.66683 11.0333 9.36683 11.3333 9.00016 11.3333H1.00016C0.633496 11.3333 0.333496 11.0333 0.333496 10.6667C0.333496 10.3 0.633496 10 1.00016 10Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div
      className={classNames({
        [styles.buttonItemElement]: styleEl !== 'button',
        [styles.uploadWrapper]: styleEl === 'button',
      })}
    >
      <label
        className={styles.upload}
        data-testid={'droppable'}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {state === 'loading' ? (
          <p className={styles.messageText}>{UPLOAD_IS_IN_PROGRESS_TEXT}</p>
        ) : state === 'error' ? (
          <p
            className={classNames({
              [styles.messageText]: true,
              [styles.error]: true,
            })}
          >
            {UPLOAD_ERROR_TEXT}
          </p>
        ) : (
          <>
            {styleEl === 'button' && <div className={styles.text}>Upload</div>}
            <span className={styles.arrow}>
              {styleEl === 'button' ? svgIconButton : svgIconDefault}
            </span>
          </>
        )}
        <input
          className={styles.input}
          accept={fileTypes.join(', ')}
          onChange={onChangeInput}
          type="file"
        />
      </label>
    </div>
  );
};
