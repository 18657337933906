import { FC } from 'react';

/* istanbul ignore file */

interface Props {
  className?: string;
}

const WowzaIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      width="36"
      height="36"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-35.5 26 150 150"
      enableBackground="new -35.5 26 150 150"
      xmlSpace="preserve"
    >
      <g>
        <defs>
          <rect id="SVGID_1_" x="-745.5" y="-514" width="1400" height="1060" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
      </g>
      <g id="_x36_KX8MU.tif">
        <g>
          <path
            fill="#FDFDFC"
            d="M23.4,51.1c-2.6-0.4-4.2,0.7-5.8,1.4c-25,11.4-37.1,39.5-28.3,65.6c7.9,23.4,32.1,38.8,56.2,35.6
			c25.2-3.3,45.2-23.5,46.8-47.2c0.1-1.5,1-3.6-0.5-4.6c-1.3-0.8-2.7,0.8-4,1.5c-20.4,10.9-40.7,21.9-61.1,32.7
			c-2.7,1.4-5.7,4.8-9,1.6c-3.2-3.1-0.1-6.3,1.1-9.1c5.9-14.7,11.9-29.2,17.9-43.9c0.7-1.7,1.6-4,0.2-5.1c-1.7-1.3-3.2,0.8-4.6,2
			c-6.5,5.4-13,11-19.5,16.4c-1.5,1.3-3.2,2.4-4.9,3.4c-1.5,0.9-3.2,1.3-4.7,0.1c-1.6-1.2-1.1-3.1-0.6-4.6c0.6-1.9,1.6-3.7,2.4-5.5
			C11,78.1,17.1,64.8,23.4,51.1z M43.9,89.3c-0.3,3.1,0.5,4.5,3.6,2.9c0.9-0.5,1.8-1,2.5-1.6c8.7-7.9,17.4-16,26.2-23.8
			c2.3-2.1,1.1-3.2-0.4-4.7c-10.6-9.6-22.9-14.5-37.3-14.2c-2.2,0-3.3,0.9-4.1,2.7c-2.7,6.3-5.6,12.4-8.2,18.7
			c-0.6,1.4-2.1,3.4-0.4,4.5c1.8,1.3,3.3-0.7,4.7-1.9c5.4-4.4,10.7-9,16.2-13.3c2.1-1.6,4.3-5.2,7.1-3.3c2.9,2-0.1,5.2-0.9,7.6
			C49.7,71.8,46.2,80.4,43.9,89.3z"
          />
          <path
            fill="#FE8503"
            d="M23.4,51.1C17.1,64.8,11,78.1,4.9,91.4c-0.8,1.8-1.8,3.6-2.4,5.5c-0.5,1.6-1,3.4,0.6,4.6
			c1.5,1.2,3.2,0.8,4.7-0.1c1.7-1,3.4-2.1,4.9-3.4c6.5-5.4,13-11,19.5-16.4c1.3-1.1,2.9-3.3,4.6-2c1.4,1.1,0.5,3.4-0.2,5.1
			c-6,14.6-12,29.2-17.9,43.9c-1.2,2.9-4.2,6.1-1.1,9.1c3.3,3.2,6.3-0.2,9-1.6c20.4-10.8,40.8-21.8,61.1-32.7c1.3-0.7,2.7-2.3,4-1.5
			c1.6,1,0.6,3,0.5,4.6c-1.5,23.8-21.5,43.9-46.8,47.2c-24.1,3.1-48.3-12.2-56.2-35.6C-19.5,92-7.4,63.9,17.6,52.5
			C19.2,51.8,20.7,50.7,23.4,51.1z"
          />
          <path
            fill="#FE8605"
            d="M43.9,89.3c2.3-8.8,5.8-17.5,8.9-26.2c0.9-2.4,3.8-5.6,0.9-7.6c-2.8-1.9-5,1.6-7.1,3.3
			C41.2,63,35.9,67.6,30.5,72c-1.4,1.1-2.9,3.1-4.7,1.9c-1.6-1.2-0.2-3.1,0.4-4.5c2.7-6.3,5.5-12.5,8.2-18.7
			c0.8-1.9,1.9-2.7,4.1-2.7c14.4-0.2,26.7,4.6,37.3,14.2c1.6,1.4,2.8,2.6,0.4,4.7C67.4,74.6,58.7,82.6,50,90.6
			c-0.7,0.7-1.6,1.2-2.5,1.6C44.4,93.8,43.6,92.4,43.9,89.3z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WowzaIcon;
