import React, {
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TranscriptContext } from '@/contexts/TranscriptContext';
import { Box, Typography } from '@mui/material';
import { TranscriptItem as TranscriptItemModel } from '@/types/transcript';
import styled from '@emotion/styled';
const findTranscriptItem = (items: TranscriptItemModel[], time: number): TranscriptItemModel => {
  let low = 0;
  let high = items.length;
  while (low < high) {
    const mid = (low + high) >>> 1;
    if (items[mid].end < time) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return items[low];
};
interface TranscriptItemProps {
  item: TranscriptItemModel;
  selected: boolean;
  editable?: boolean | undefined;
  onSelect(): void;
  onUpdate(text: string): void;
}
const StyledTranscriptItem = styled.span<Pick<TranscriptItemProps, 'selected' | 'editable'>>`
  color: ${(props) => (props.selected ? '#DD3A41' : 'inherit')};
  cursor: ${(props) => (props.editable ? 'text' : 'pointer')};
`;
const TranscriptItem = ({
  item,
  selected,
  editable,
  onSelect,
  onUpdate,
}: TranscriptItemProps): ReactElement => {
  const ref = useRef<HTMLElement>(null);
  const onKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (ref.current) {
      ref.current.innerText = `${item.text} `;
    }
  }, [item]);
  const onClick = useCallback(
    (event: MouseEvent): void => {
      onSelect();
      if (editable) {
        setTimeout(function () {
          ref.current?.focus();
        }, 0);
      }
    },
    [editable, onSelect, ref],
  );
  const onInput = useDebouncedCallback((): void => {
    onUpdate(ref.current?.innerText || item.text);
  }, 500);
  return (
    <StyledTranscriptItem
      data-start-time={item.start}
      ref={ref}
      onClick={onClick}
      onKeyDown={onKeyPress}
      onInput={onInput}
      selected={selected}
      editable={editable}
      contentEditable={selected && editable}
      suppressContentEditableWarning={true}
    ></StyledTranscriptItem>
  );
};
const Transcript = ({ height }: { height?: number }): ReactElement => {
  const transcriptContainerRef = useRef<HTMLDivElement>(null);
  const [currentTranscriptionItem, setCurrentTranscriptionItem] =
    useState<TranscriptItemModel | null>(null);
  const { transcript, editable, currentTime, onSelectItem, onUpdateItem, timeCodes } =
    useContext(TranscriptContext);
  const transcriptItems = useMemo<TranscriptItemModel[]>(() => {
    if (!transcript) {
      return [];
    }
    const items: TranscriptItemModel[] = [];
    let index = 0;
    let lastIndex = 0;
    for (const item of transcript.items) {
      if (item.start > 0) {
        items.push({ ...item });
        lastIndex = index;
        index++;
      } else if (lastIndex > -1) {
        items[lastIndex].text += item.text;
      }
    }
    return items;
  }, [transcript]);
  useEffect(() => {
    if (transcriptItems.length === 0 || currentTime == null) {
      return;
    }
    const item = findTranscriptItem(transcriptItems, currentTime);
    setCurrentTranscriptionItem(item);
  }, [transcriptItems, currentTime]);
  useEffect(() => {
    if (!currentTranscriptionItem || !transcriptContainerRef.current) {
      return;
    }

    const transcriptElement = transcriptContainerRef.current;
    const currentItemElement = transcriptElement.querySelector(
      `[data-start-time="${currentTranscriptionItem.start}"]`,
    );

    if (currentItemElement && currentItemElement instanceof HTMLElement) {
      const scrollPosition =
        currentItemElement.offsetTop +
        currentItemElement.clientHeight -
        transcriptElement.clientHeight;
      if (transcriptElement.scrollTop < scrollPosition) {
        transcriptElement.scrollTop = scrollPosition;
      }
    }
  }, [currentTranscriptionItem, transcriptContainerRef]);

  const selectItem = useCallback(
    (item: TranscriptItemModel) => {
      setCurrentTranscriptionItem(item);
      onSelectItem(item);
    },
    [onSelectItem],
  );
  const updateItem = useCallback(
    (item: TranscriptItemModel, text: string) => {
      onUpdateItem(item, text);
    },
    [onUpdateItem],
  );
  if (!transcript) {
    return <></>;
  }
  return (
    <div>
      <Box
        sx={{ height: height ? `${height}px` : '400px', overflowY: 'auto' }}
        ref={transcriptContainerRef}
      >
        {timeCodes ? (
          <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.75 }}>
            {transcriptItems.map((item, index) => {
              if (item.start >= timeCodes.start && item.end <= timeCodes.end) {
                return (
                  <TranscriptItem
                    item={item}
                    selected={false}
                    editable={editable}
                    key={item.start}
                    onSelect={() => console.log('')}
                    onUpdate={(text) => console.log('')}
                  />
                );
              }
            })}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.75 }}>
            {transcriptItems.map((item, index) => (
              <TranscriptItem
                item={item}
                selected={currentTranscriptionItem?.start === item.start}
                editable={editable}
                key={item.start}
                onSelect={() => selectItem(item)}
                onUpdate={(text) => updateItem(item, text)}
              />
            ))}
          </Typography>
        )}
      </Box>
    </div>
  );
};
export default Transcript;
