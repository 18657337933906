/* eslint-disable */
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import Assets from '../entities/brand-kit-list-item';
import Api from './api';
import { SanityClient } from '@sanity/client';
import { Result } from '../entities/sanity-list-item';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';

const listAssetsKey = 'list-assets';

export enum BrandKitAssets {
  intro = 'intro',
  outro = 'outro',
}

export function useGetAssets(): UseQueryResult<Assets> {
  return useQuery(listAssetsKey, async () => {
    return await Api.get('/assets');
  });
}

export function useUploadAssets(): UseMutationResult<
  void,
  unknown,
  { type: BrandKitAssets; file: File }
> {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ type, file }) => {
      const formData = new FormData();
      formData.append('file', file);

      await Api.post(`/assets/${type}`, formData);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([listAssetsKey]),
    },
  );
}

export function useUploadFonts(): UseMutationResult<void, unknown, { type: string; font: string }> {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ type, font }) => {
      const data = {
        name: font,
      };
      await Api.post(`/assets/${type}`, data);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([listAssetsKey]),
    },
  );
}
export function useUploadColor(): UseMutationResult<
  void,
  unknown,
  { type: string; color: string }
> {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ type, color }) => {
      const data = {
        name: color,
      };
      await Api.post(`/assets/${type}`, data);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([listAssetsKey]),
    },
  );
}

export function useSanity(client: SanityClient): UseQueryResult<Result> {
  return useQuery('result', async () => {
    return await client.fetch<Result[]>('*[_type == "post"]').then((result) => {
      return result[0];
    });
  });
}

export function urlFor(source: SanityImageSource, client: SanityClient): ImageUrlBuilder {
  const builder = imageUrlBuilder(client);
  return builder.image(source);
}
