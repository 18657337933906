/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import Loading from '@/components/Loading';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import styles from '../Users/Users.module.scss';
import { useGetBundleItems } from '@/adapters/users';
import Api from '@/adapters/api';

interface FormValues {
  name: string;
  email: string;
  password: string;
  integrations: string[];
}

const getValidationSchema = (isEditMode: boolean) => {
  if (isEditMode) {
    return Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
    });
  } else {
    return Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    });
  }
};

const UserForm: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const isEditMode = Boolean(userId);
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { data: bundleItems = [], isLoading: loader } = useGetBundleItems();

  useEffect(() => {
    setIsLoading(loader);
  }, [loader]);

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    email: '',
    password: '',
    integrations: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (isEditMode) {
        setIsLoading(true);
        try {
          const response: any = await Api.get(`user/findByEmail/${userId}`);
          if (response) {
            setInitialValues({
              name: response.name,
              email: response.email,
              password: '', // Do not pre-fill the password field
              integrations: response?.integrations || [],
            });
            setId(response.id);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
        setIsLoading(false);
      }
    };

    fetchData();

    // Cleanup function (optional):
    return () => {
      // Perform any cleanup actions here, if necessary
    };
  }, [isEditMode, userId]);

  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true);

    if (isEditMode) {
      await Api.patch(`user/update/${id}`, values);
      navigate('/users');
    } else {
      const updateValue = { ...values, role: 'temporary_enterprise' };
      console.log('updateValue', updateValue);
      const data = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/user`, updateValue, {
        headers: {
          Authorization: `${process.env.REACT_APP_AUTH_WEB_HOOK_KEY}`,
        },
      });
      if (data) {
        navigate('/users');
      }
    }

    setIsLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = getValidationSchema(isEditMode);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      handleSubmit(values);
      setSubmitting(false);
    },
  });

  const handleIntegrationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      formik.setFieldValue('integrations', [...formik.values.integrations, value]);
    } else {
      formik.setFieldValue(
        'integrations',
        formik.values.integrations.filter((id) => id !== value),
      );
    }
  };

  console.log(formik.isValid, formik.dirty, formik.isSubmitting);

  return (
    <>
      <div className={styles.navbar}>
        <h1 className={styles.projectsHeadTitle}>{isEditMode ? 'Edit User' : 'Create User'}</h1>
      </div>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ flexGrow: 1 }}>
        {isLoading && <Loading />}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
            />
          </Grid>
          {!isEditMode && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={styles.navbar}>
              <h1 className={styles.projectsHeadTitle}>Cloud Integrations</h1>
            </div>
            <Grid container spacing={2}>
              {bundleItems.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={formik.values.integrations.includes(item.id)}
                        onChange={handleIntegrationChange}
                        value={item.id}
                      />
                    }
                    label={item.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end" alignItems="center">
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  isEditMode ? false : !(formik.isValid && formik.dirty) || formik.isSubmitting
                }
              >
                {isEditMode ? 'Update' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserForm;
