/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable */
import {
  ChangeEvent,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, height, width } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { PROJECTS_URL } from '@/routePaths';
import styles from './ProjectVideo.module.scss';
import { useGetProject } from '@/adapters/projects';
import Loading from '@/components/Loading';
import EditableLabel from '@//components/EditableLabel';
// import square from '@/assets/images/square.svg';
// import standard from '@/assets/images/standard.svg';
// import vertical from '@/assets/images/vertical.svg';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import boldIcon from '@/assets/images/bold-solid.svg';
import italicIcon from '@/assets/images/italic-solid.svg';
import alignLeftIcon from '@/assets/images/left-alignment.svg';
import alignCenterIcon from '@/assets/images/center-alignment.svg';
import alignRightIcon from '@/assets/images/right-alignment.svg';
import colorIcon from '@/assets/images/paint.svg';
import FontPicker from 'font-picker-react';
import uploadIcon from '@/assets/images/upload.svg';

import Video from '@/components/Video';
import { useProcessTopAndTail, useEditSegment } from '@/adapters/edits';
import leftSlackIcon from '../../assets/images/left-slack.svg';
import VideoPlayer from '@/components/Video/VideoPlayer';
import { ProjectProvider } from '@/contexts/ProjectContext';
import { VideoProvider } from '@/contexts/VideoContext';
import Clip from '@/entities/clip';
import Edit from '@/entities/edit';
import VideoActions from '@/components/Video/VideoActions';
import VideoTimelineControl from '@/components/Video/VideoTimelineControl';
import { useGetIntegrations } from '@/adapters/integrations';
import { UserContext } from '@/contexts/UserContext';
import { UserSubscriptionState } from '@/entities/subscription';
import { Checkbox } from '@mui/material';
import Api from '../../adapters/api';
import BrandKitVideoItem from '@/components/BrandKitVideoItem';
import { FileUpload } from '@/components/FileUpload';

interface Props {
  refetchInterval?: number;
  initialActiveFontFamily?: string;
}

const ProjectVideo: FC<Props> = ({ refetchInterval, initialActiveFontFamily }) => {
  const { projectId, videoId = 'original' } = useParams<{ projectId: string; videoId: string }>();
  const { userState } = useContext(UserContext);
  const boxRef1 = useRef<HTMLDivElement>(null);
  const boxRef2 = useRef<HTMLDivElement>(null);
  const boxRef3 = useRef<HTMLDivElement>(null);
  const [addBg, setAddBg] = useState<boolean>(false);
  const { data: project, refetch: reloadProject, isLoading } = useGetProject(projectId!);
  const { data: integrations = [], refetch } = useGetIntegrations();

  const { mutateAsync: processTopAndTail } = useProcessTopAndTail(projectId!);
  const [wowza, setWowza] = useState<boolean>(false);
  const [jwp, setJwp] = useState<boolean>(false);
  const { mutateAsync: editSegment } = useEditSegment(projectId!);
  const [segmentId, setSegmentId] = useState<string | null>(null);
  const [videoSize] = useState<string>('16:9');
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState<string>('');
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState<boolean>(false);
  const [headingTitle, setHeadingTitle] = useState<string>('');
  const [activeFontFamily, setActiveFontFamily] = useState<string | null>(null);
  const fontsizeArray: number[] = Array.from({ length: 17 }, (_, index) => index * 2 + 8);
  const [selectedFontSize, setSelectedFontSize] = useState<number>(12);
  const [logoPosition, setLogoPosition] = useState<string>('Top Right');
  const [selectedImage, setSelectedImage] = useState<Blob>(new Blob());
  const [textInputFocus, setTextInputFocus] = useState<boolean>(false);
  const [textAlignment, setTextAlignment] = useState<string>('center');
  const [state, setState] = useState<UserSubscriptionState[]>([]);
  const [addLogo, setAddLogo] = useState<boolean>(false);
  const [addIntro, setAddIntro] = useState<boolean>(false);
  const [addOutro, setAddOutro] = useState<boolean>(false);
  useEffect(() => {
    if (userState) {
      setState(
        userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        ),
      );
    }
  }, [userState]);

  // const handleVideoSizeChange = (event: SelectChangeEvent): void => {
  //   setSelectedVideoSize(event.target.value);
  // };

  const handleColorChange = (event: SelectChangeEvent): void => {
    setSelectedColor(event.target.value);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedColor(event.target.value);
  };

  const handleBackgroundColorChange = (event: SelectChangeEvent): void => {
    setSelectedBackgroundColor(event.target.value);
  };

  const handleBackgroundColorTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedBackgroundColor(event.target.value);
  };

  const handleToggleBold = (): void => {
    setIsBold(!isBold);
  };

  const handleToggleItalic = (): void => {
    setIsItalic(!isItalic);
  };

  const handleFontSizeChange = (event: SelectChangeEvent<string>): void => {
    const value = parseInt(event.target.value, 10);
    setSelectedFontSize(value);
  };

  const handleLogoPosition = (event: SelectChangeEvent): void => {
    setLogoPosition(event.target.value);
  };

  useEffect(() => {
    setWowza(integrations.some((integration: any) => integration.platform['wowza']));
    setJwp(integrations.some((integration: any) => integration.platform['jwp']));
  }, [integrations]);

  const navigate = useNavigate();

  const handleAddBg = (event: ChangeEvent<HTMLInputElement>): void => {
    // event.preventDefault();
    // event.stopPropagation();
    if (event.target.checked === false) {
      setSelectedBackgroundColor('transparent');
    }
    setAddBg(event.target.checked);
  };

  const handleAddLogo = (event: ChangeEvent<HTMLInputElement>): void => {
    setAddLogo(event.target.checked);
  };

  const handleAddIntro = (event: ChangeEvent<HTMLInputElement>): void => {
    setAddIntro(event.target.checked);
  };

  const handleAddOutro = (event: ChangeEvent<HTMLInputElement>): void => {
    setAddOutro(event.target.checked);
  };

  useEffect(() => {
    const handleClickBox1 = (event: MouseEvent): void => {
      // event.stopPropagation();
      // Add your condition here
      if (state.length === 0 && userState && userState.trialDays === 0) {
        navigate('/subscription/plans');
      }
    };

    const handleClickBox2 = (event: MouseEvent): void => {
      // event.stopPropagation();
      if (state.length === 0 && userState && userState.trialDays === 0) {
        navigate('/subscription/plans');
      }
    };

    const handleClickBox3 = (event: MouseEvent): void => {
      // event.stopPropagation();
      if (state.length === 0 && userState && userState.trialDays === 0) {
        navigate('/subscription/plans');
      }
    };

    // Add event listeners to the elements referenced by refs
    if (boxRef1.current) {
      boxRef1.current.addEventListener('click', handleClickBox1);
    }

    if (boxRef2.current) {
      boxRef2.current.addEventListener('click', handleClickBox2);
    }

    if (boxRef3.current) {
      boxRef3.current.addEventListener('click', handleClickBox3);
    }

    // Cleanup the event listeners when the component unmounts
    return () => {
      if (boxRef1.current) {
        boxRef1.current.removeEventListener('click', handleClickBox1);
      }

      if (boxRef2.current) {
        boxRef2.current.removeEventListener('click', handleClickBox2);
      }

      if (boxRef3.current) {
        boxRef3.current.removeEventListener('click', handleClickBox3);
      }
    };
  }, [userState, state]);

  useEffect(() => {
    if (project?.edits.some((el) => el.status === 'Processing')) {
      const interval = setInterval(
        () => {
          void reloadProject();
          void refetch();
        },
        // istanbul ignore next
        refetchInterval ?? 5000,
      );
      return () => clearInterval(interval);
    }
  }, [project, refetchInterval, reloadProject]);

  let videoComponent: ReactNode;
  let video: Clip | undefined | null = null;
  let edit: Edit | undefined | null = null;

  const headingStyle = useMemo(() => {
    let color: any = 'black';
    if (project) {
      color =
        project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed') &&
        project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed')
          ?.overlaySettings &&
        project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed')
          ?.overlaySettings.color
          ? project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed')
              ?.overlaySettings.color
          : 'black';
    }
    return {
      color: selectedColor ?? color,
      backgroundColor: selectedBackgroundColor,
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal',
      fontFamily: activeFontFamily,
      fontSize: `${selectedFontSize}px`,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '5px',
      paddingBottom: '5px',
    };
  }, [
    project,
    selectedColor,
    selectedBackgroundColor,
    isBold,
    isItalic,
    activeFontFamily,
    selectedFontSize,
  ]);

  const headingContainerStyle = useMemo(() => {
    return {
      display: 'flex',
      justifyContent:
        textAlignment === 'left' ? 'left' : textAlignment === 'right' ? 'right' : 'center',
      width: '100%',
      position: 'absolute',
      top: 50,
    };
  }, [
    project,
    selectedColor,
    selectedBackgroundColor,
    isBold,
    isItalic,
    activeFontFamily,
    selectedFontSize,
    textAlignment,
  ]);
  const imageAtr = useMemo(() => {
    const position: { [key: string]: any } = {
      position: 'absolute',
      objectFit: 'contain',
      width: 170,
      height: 50,
      zIndex: 1,
    };
    if (logoPosition === 'Top Right') {
      position['top'] = 60;
      position['right'] = '30%';
      position['right'] = videoSize === '1:1' ? '30%' : videoSize === '9:16' ? '32%' : 10;
    } else if (logoPosition === 'Top Left') {
      position['top'] = 60;
      position['left'] = '28%';
      position['left'] = videoSize === '1:1' ? '28%' : videoSize === '9:16' ? '32%' : 10;
    } else if (logoPosition === 'Bottom Left') {
      position['bottom'] = 160;
      position['left'] = '28%';
      position['left'] = videoSize === '1:1' ? '28%' : videoSize === '9:16' ? '32%' : 10;
    } else if (logoPosition === 'Bottom Right') {
      position['bottom'] = 160;
      position['right'] = '30%';
      position['right'] = videoSize === '1:1' ? '30%' : videoSize === '9:16' ? '32%' : 10;
    }
    return {
      image: selectedImage,
      position: position,
    };
  }, [selectedImage, logoPosition, videoSize]);

  const handleSave = async () => {
    const logoPath: any =
      project && project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed');
    const overlaySettings: any =
      project &&
      project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed') &&
      project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed')
        ?.overlaySettings;
    const spiltedPath = window.location.pathname.split('/');
    const segId = segmentId ?? spiltedPath[3];
    const temp = project?.edits.filter((edit) => edit.id === spiltedPath[3]);
    const data = {
      name: spiltedPath[3],
      overlay: {
        startTimestamp: `${temp?.[0]?.timing?.start}`,
        endTimestamp: `${temp?.[0]?.timing?.end}`,
      },
      overlaySettings: {
        ...(headingTitle && {
          title: headingTitle,
        }),
        color: selectedColor
          ? selectedColor
          : overlaySettings && overlaySettings.color
          ? overlaySettings.color
          : 'black',
        ...(addBg && {
          backgroud: selectedBackgroundColor
            ? selectedBackgroundColor
            : overlaySettings && overlaySettings.backgroud
            ? overlaySettings.backroud
            : '',
        }),
        fontSize: selectedFontSize ? `${selectedFontSize}` : '24',
        font: activeFontFamily
          ? activeFontFamily
          : overlaySettings && overlaySettings.font
          ? overlaySettings.font
          : 'Roboto',
        justified:
          textAlignment === 'center'
            ? 'top center'
            : textAlignment === 'left'
            ? 'top left'
            : 'top-right',
        ...(addLogo &&
          logoPath?.overlaySettings?.logo && {
            logo: logoPath?.overlaySettings?.logo,
            logoPosition: logoPosition,
          }),
        ...(addIntro &&
          logoPath?.overlaySettings?.intro && {
            intro: logoPath?.overlaySettings?.intro,
          }),
        ...(addOutro &&
          logoPath?.overlaySettings?.outro && {
            outro: logoPath?.overlaySettings?.outro,
          }),
      },
    };

    await Api.patch(`/projects/${projectId}/segments/${segId}/overlay`, data);
    !segmentId
      ? window.location.reload()
      : (window.location.pathname = `/projects/1702545844954/${segmentId}`);
  };

  const handleRevertToOriginal = async () => {
    const spiltedPath = window.location.pathname.split('/');
    const segId = segmentId ?? spiltedPath[3];
    const data = {};

    await Api.post(`/projects/${projectId}/segments/${segId}/revert`, data);
    !segmentId
      ? window.location.reload()
      : (window.location.pathname = `/projects/1702545844954/${segmentId}`);
  };

  const uploadImage = async (file: File, type: string) => {
    const spiltedPath = window.location.pathname.split('/');
    const segId = segmentId ?? spiltedPath[3];
    const formData = new FormData();
    formData.append('files[]', file);
    await Api.post(`projects/${projectId}/segments/${segId}/asset/${type}`, formData);
    !segmentId
      ? void reloadProject()
      : (window.location.pathname = `/projects/1702545844954/${segmentId}`);
  };

  const loadingComponent = (
    <div className={styles.box}>
      <Loading text="Your video will be ready shortly..." />
    </div>
  );

  if (!project || !project.original) {
    videoComponent = <></>;
  } else {
    if (videoId === 'original') {
      video = project.original;
      const topAndTailEdit = project.edits.find((e) => e.id === 'top-and-tail');
      videoComponent = project.original.fullPath ? (
        <div className={styles.box}>
          <Video
            url={project.original.fullPath}
            timing={topAndTailEdit?.timing}
            onConfirm={processTopAndTail}
            setTextForInput={() => Promise.resolve()}
            data-testid={`video-edit-1`}
            editable
          >
            <VideoPlayer />
            <VideoActions
              wowza={wowza}
              jwp={jwp}
              saveChanges={() => handleSave()}
              onRevertToOriginal={() => handleRevertToOriginal()}
            />
          </Video>
        </div>
      ) : null;
    } else {
      video = project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed');
      if (video) {
        edit = video as Edit;
        videoComponent = (
          <div className={styles.box}>
            <div className={styles.boxHeader}>
              {/* <h4>{edit.name}</h4> */}
              <EditableLabel
                text={edit.name}
                onSave={async (text) => {
                  {
                    await editSegment({
                      name: text,
                      segmentId: segmentId ? segmentId : edit?.id,
                    });

                    setSegmentId(text.replaceAll(' ', '-'));
                    // window.location.replace(
                    //   window.location.pathname.replace(
                    //     window.location.pathname
                    //       .split('/')
                    //       [window.location.pathname.split('/').length - 1].replaceAll(' ', '-'),
                    //     text,
                    //   ),
                    // );
                  }
                }}
              />
            </div>
            <Video
              inputFocus={textInputFocus}
              setTextForInput={() => {
                setHeadingTitle((prevKey) => prevKey + ' ');
                // setHeadingTitle(headingTitle + ' ')
              }}
              //@ts-ignore
              url={
                project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed') &&
                project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed')!
                  .overlay &&
                project.edits.find((edit) => edit.id === videoId && edit.status === 'Processed')!
                  .overlay.path
                  ? project.edits.find(
                      (edit) => edit.id === videoId && edit.status === 'Processed',
                    )!.overlay.path
                  : edit.fullPath!
              }
              onConfirm={() => Promise.resolve()}
            >
              {/* <div
                style={{ width: '100%', height: 250, backgroundColor: 'black', flexWrap: 'wrap' }}
              > */}
              <VideoPlayer
                headingTitle={headingTitle}
                headingStyle={headingStyle}
                headingContainerStyle={headingContainerStyle}
                videoSize={videoSize}
                imageAtr={imageAtr}
                addLogo={addLogo}
                logo={
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay &&
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay &&
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay.status !== 'Processed'
                    ? project.edits.find(
                        (edit) => edit.id === videoId && edit.status === 'Processed',
                        //@ts-ignore
                      )?.overlaySettings?.logo
                    : selectedImage.size !== 0
                    ? URL.createObjectURL(selectedImage)
                    : undefined
                }
              />
              {/* </div> */}
              <VideoActions
                status={
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay &&
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay?.status === 'Processing'
                    ? true
                    : false
                }
                disabledRevert={
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay?.status === 'Processed'
                    ? false
                    : true
                }
                wowza={wowza}
                jwp={jwp}
                saveChanges={() => handleSave()}
                onRevertToOriginal={() => handleRevertToOriginal()}
              />
              <div
                className={
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay &&
                  project.edits.find(
                    (edit) => edit.id === videoId && edit.status === 'Processed',
                    //@ts-ignore
                  )?.overlay.status
                    ? styles.disabledAll
                    : ''
                }
              >
                {/* <VideoTimelineControl
                  //@ts-ignore
                  introLink={video?.overlaySettings?.intro}
                  //@ts-ignore
                  outroLink={video?.overlaySettings?.outro}
                  uploadAssetMethod={uploadImage}
                /> */}
              </div>
            </Video>
          </div>
        );
      }
    }
  }

  return (
    <>
      {isLoading || !project || !videoComponent ? (
        loadingComponent
      ) : (
        <ProjectProvider project={project}>
          <div className={styles.projectDetailInner}>
            <div className={styles.routePaths}>
              <div>
                <Link to={PROJECTS_URL} className={`${styles.burgerContentItem} projects`}>
                  Projects
                </Link>
              </div>
              <div className={styles.leftSlack}>
                <img src={leftSlackIcon} alt="left" />
              </div>
              <div className={styles.currentProjectName}>{project.name}</div>
            </div>
            <div className={styles.projectDetailHeader}>
              <div>
                {/* <div className={styles.projectNameInputWrapper}>
                  <EditableLabel
                    text={project.name}
                    onSave={(text) => editProject({ name: text })}
                    testId="project-name"
                  />
                </div> */}
              </div>
            </div>

            <Box sx={{ maxWidth: '100%' }}>
              <Grid container flexDirection={{ xs: 'column-reverse', md: 'row' }}>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    opacity:
                      (userState && state.length === 0 && userState.trialDays === 0) ||
                      (project.edits.find(
                        (edit) => edit.id === videoId && edit.status === 'Processed',
                        //@ts-ignore
                      )?.overlay &&
                        project.edits.find(
                          (edit) => edit.id === videoId && edit.status === 'Processed',
                          //@ts-ignore
                        )?.overlay.status)
                        ? '0.4'
                        : '10',
                    pointerEvents:
                      userState && state.length === 0 && userState.trialDays === 0
                        ? 'unset'
                        : project.edits.find(
                            (edit) => edit.id === videoId && edit.status === 'Processed',
                            //@ts-ignore
                          )?.overlay &&
                          project.edits.find(
                            (edit) => edit.id === videoId && edit.status === 'Processed',
                            //@ts-ignore
                          )?.overlay.status
                        ? 'none'
                        : 'unset',
                  }}
                >
                  <h2 className={styles.projectsSectionHeading}>Video Settings</h2>
                  {/* Resize Clip */}
                  {/* <div>
                    <h3 className={styles.projectsFunctionHeading}>Resize Clip:</h3>
                    <FormControl className={styles.resizingDropDown}>
                      <Select
                        value={videoSize}
                        onChange={handleVideoSizeChange}
                        displayEmpty
                        className={styles.resizingOptions}
                      >
                        <MenuItem value={'16:9'}>
                          <div className={styles.resizingOption}>
                            <img src={standard} alt="standard" />
                            <strong>Standard</strong>
                            <p>(16:9)</p>
                          </div>
                        </MenuItem>
                        <MenuItem value={'9:16'}>
                          <div className={styles.resizingOption}>
                            <img src={vertical} alt="vertical" />
                            <strong>Vertical</strong>
                            <p>(9:16)</p>
                          </div>
                        </MenuItem>
                        <MenuItem value={'1:1'}>
                          <div className={styles.resizingOption}>
                            <img src={square} alt="square" />
                            <strong>Square</strong>
                            <p>(1:1)</p>
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}
                  {/* Title */}
                  <Box className={styles.resizingDropDown} ref={boxRef1}>
                    <h3 className={styles.projectsFunctionHeading}>Title:</h3>
                    <TextField
                      id="outlined-basic"
                      label="Headline Title"
                      variant="outlined"
                      multiline
                      rows={2}
                      onBlur={(e) => {
                        setTextInputFocus(false);
                      }}
                      onFocusCapture={(e) => {
                        setTextInputFocus(true);
                      }}
                      onChange={(e) => {
                        setHeadingTitle(e.target.value);
                      }}
                      sx={{ width: '100%' }}
                      InputProps={{
                        style: {
                          fontWeight: isBold ? 'bold' : 'normal',
                          fontStyle: isItalic ? 'italic' : 'normal',
                          fontFamily: activeFontFamily
                            ? activeFontFamily
                            : project.edits.find(
                                (edit) => edit.id === videoId && edit.status === 'Processed',
                              )?.overlaySettings.font
                            ? project.edits.find(
                                (edit) => edit.id === videoId && edit.status === 'Processed',
                              )?.overlaySettings.font
                            : 'Roboto',
                          fontSize: `${selectedFontSize}px`,
                        },
                      }}
                    />
                  </Box>
                  {/* Title Style Elements */}
                  <Box className={styles.resizingDropDown} ref={boxRef2}>
                    <h3 className={styles.projectsFunctionHeading}>Title Style Elements:</h3>
                    <List sx={{ border: '1px solid #a7a7a7', borderRadius: '5px' }}>
                      {/* Font */}
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <div>
                          <p className={styles.projectsFunctionHeading}>
                            <strong>Font</strong>
                          </p>
                        </div>
                        <div className={styles.fontStyling}>
                          <FontPicker
                            families={[
                              'Arimo',
                              'Barlow',
                              'DM Sans',
                              'Dosis',
                              'IBM Plex Sans',
                              'Inconsolata',
                              'Inter',
                              'Kanit',
                              'Karla',
                              'Lora',
                              'Noto Sans',
                              'Open Sans',
                              'Poppins',
                              'PT Sans',
                              'Raleway',
                              'Roboto',
                              'Titillium Web',
                            ]}
                            apiKey="AIzaSyCbKCvln87bV6cUuP87TG2hm6-sL4SF-d8"
                            activeFontFamily={
                              activeFontFamily
                                ? activeFontFamily
                                : project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings.font
                                ? project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings.font
                                : 'Roboto'
                            }
                            onChange={(nextFont) => setActiveFontFamily(nextFont.family)}
                          />
                          <Select
                            value={selectedFontSize.toString()}
                            onChange={handleFontSizeChange}
                            sx={{ height: '35px', alignItems: 'center' }}
                          >
                            {fontsizeArray.map((size) => (
                              <MenuItem key={size} value={size.toString()}>
                                {size}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </ListItem>
                      {/* Colour */}
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <div>
                          <p className={styles.projectsFunctionHeading}>
                            <strong>Color</strong>
                          </p>
                        </div>
                        <div>
                          <input
                            type="color"
                            id="colorpicker"
                            style={{
                              border: 'none',
                              background: 'none',
                              width: '25px',
                              height: '25px',
                            }}
                            value={
                              selectedColor
                                ? selectedColor
                                : project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.color
                                ? project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.color
                                : 'black'
                            }
                            onChange={handleColorChange}
                          />
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            sx={{ width: '100px', padding: '0 10px' }}
                            value={
                              selectedColor
                                ? selectedColor
                                : project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.color
                                ? project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.color
                                : 'black'
                            }
                            onChange={handleTextFieldChange}
                          />
                          <img src={colorIcon} alt="color-picker" />
                        </div>
                      </ListItem>
                      {/* Background Color */}
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <div>
                          <p className={styles.projectsFunctionHeading}>
                            <strong>Add Backgroud Color?</strong>
                          </p>
                        </div>
                        <Checkbox defaultChecked={addBg} color="default" onChange={handleAddBg} />
                      </ListItem>
                      {addBg && (
                        <ListItem sx={{ justifyContent: 'space-between' }}>
                          <div>
                            <p className={styles.projectsFunctionHeading}>
                              <strong>Background Color</strong>
                            </p>
                          </div>
                          <div>
                            <input
                              type="color"
                              id="colorpicker"
                              style={{
                                border: 'none',
                                background: 'none',
                                width: '25px',
                                height: '25px',
                              }}
                              value={selectedBackgroundColor}
                              onChange={handleBackgroundColorChange}
                            />
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              sx={{ width: '100px', padding: '0 10px' }}
                              value={selectedBackgroundColor}
                              onChange={handleBackgroundColorTextFieldChange}
                            />
                            <img src={colorIcon} alt="color-picker" />
                          </div>
                        </ListItem>
                      )}
                      {/* Text Formatting */}
                      <ListItem>
                        <div className={styles.textFormatting}>
                          <div className={styles.fontStyling}>
                            {[
                              'Dosis',
                              'Inconsolata',
                              'Inter',
                              'Poppins',
                              'Arimo',
                              'Barlow',
                              'DM Sans',
                              'IBM Plex Sans',
                              'Kanit',
                              'Karla',
                              'Lora',
                              'Noto Sans',
                              'Open Sans',
                              'PT Sans',
                              'Raleway',
                              'Titillium Web',
                              'Roboto',
                            ].includes(
                              // @ts-ignore
                              activeFontFamily
                                ? activeFontFamily
                                : project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings &&
                                  project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.font
                                ? project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.font
                                : 'Roboto',
                            ) && (
                              <img
                                src={boldIcon}
                                alt="bold"
                                className={styles.stylingFontIcons}
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  backgroundColor: isBold ? '#e0e0e0' : 'transparent',
                                }}
                                onClick={handleToggleBold}
                              />
                            )}
                            {[
                              'Poppins',
                              'Arimo',
                              'Barlow',
                              'DM Sans',
                              'IBM Plex Sans',
                              'Kanit',
                              'Karla',
                              'Lora',
                              'Noto Sans',
                              'Open Sans',
                              'PT Sans',
                              'Raleway',
                              'Titillium Web',
                              'Roboto',
                            ].includes(
                              // @ts-ignore
                              activeFontFamily
                                ? activeFontFamily
                                : project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings &&
                                  project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.font
                                ? project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.font
                                : 'Roboto',
                            ) && (
                              <img
                                src={italicIcon}
                                alt="italic"
                                className={styles.stylingFontIcons}
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  backgroundColor: isItalic ? '#e0e0e0' : 'transparent',
                                }}
                                onClick={handleToggleItalic}
                              />
                            )}
                          </div>
                          <div className={styles.alignmentStyling}>
                            <img
                              src={alignLeftIcon}
                              alt="align-left"
                              className={styles.stylingFontIcons}
                              onClick={() => {
                                setTextAlignment('left');
                              }}
                            />
                            <img
                              src={alignCenterIcon}
                              alt="align-center"
                              className={styles.stylingFontIcons}
                              onClick={() => {
                                setTextAlignment('center');
                              }}
                            />
                            <img
                              src={alignRightIcon}
                              alt="align-right"
                              className={styles.stylingFontIcons}
                              onClick={() => {
                                setTextAlignment('right');
                              }}
                            />
                          </div>
                        </div>
                      </ListItem>
                    </List>
                  </Box>
                  {/* Logo */}
                  <Box className={styles.resizingDropDown} ref={boxRef3}>
                    <ListItem sx={{ justifyContent: 'space-between' }}>
                      <div>
                        <p className={styles.projectsFunctionHeading}>
                          <strong>Add Logo?</strong>
                        </p>
                      </div>
                      <Checkbox defaultChecked={addLogo} color="default" onChange={handleAddLogo} />
                    </ListItem>
                    {addLogo && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px',
                        }}
                      >
                        <h3 className={styles.projectsFunctionHeading}>Logo:</h3>
                        <div>
                          <div
                            className={
                              selectedImage.size !== 0
                                ? styles.uploadLogoIcon
                                : styles.uploadLogoIcon
                            }
                          >
                            <div>
                              {/* Hidden input for file upload */}
                              <input
                                type="file"
                                name="myImage"
                                accept=".png, .jpg, .jpeg"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                  if (
                                    event &&
                                    event.target &&
                                    event.target.files &&
                                    event.target.files[0]
                                  ) {
                                    setSelectedImage(event.target.files[0]);
                                    uploadImage(event.target.files[0], 'logo');
                                  }
                                }}
                              />

                              {/* Custom-styled text or button to trigger file input */}
                              <label
                                htmlFor="fileInput"
                                style={{
                                  cursor: 'pointer',
                                  padding: '10px',
                                  border: '0px solid #ccc',
                                  borderRadius: '4px',
                                }}
                              >
                                <div className={styles.uploadLogoIconMain}>
                                  <img
                                    src={
                                      selectedImage.size !== 0
                                        ? URL.createObjectURL(selectedImage)
                                        : project.edits.find(
                                            (edit) =>
                                              edit.id === videoId && edit.status === 'Processed',
                                            //@ts-ignore
                                          )?.overlaySettings?.logo
                                        ? project.edits.find(
                                            (edit) =>
                                              edit.id === videoId && edit.status === 'Processed',
                                            //@ts-ignore
                                          )?.overlaySettings?.logo
                                        : uploadIcon
                                    }
                                    alt="upload"
                                    // width={'100%'}
                                    // height={'120%'}
                                    className={styles.uploadLogoIcon}
                                  />
                                </div>
                                <p>
                                  {selectedImage.size !== 0 ||
                                  project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                    //@ts-ignore
                                  )?.overlaySettings?.logo ? null : (
                                    <strong>Upload Logo</strong>
                                  )}
                                </p>
                              </label>
                            </div>
                            {/* )} */}
                          </div>
                          <FormControl sx={{ paddingTop: '10px' }}>
                            <Select
                              value={logoPosition}
                              onChange={handleLogoPosition}
                              sx={{ height: '40px', borderRadius: '8px' }}
                            >
                              <MenuItem value={'Top Right'}>Top Right</MenuItem>
                              <MenuItem value={'Top Left'}>Top Left</MenuItem>
                              <MenuItem value={'Bottom Left'}>Bottom Left</MenuItem>
                              <MenuItem value={'Bottom Right'}>Bottom Right</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )}
                    <ListItem sx={{ justifyContent: 'space-between' }}>
                      <div>
                        <p className={styles.projectsFunctionHeading}>
                          <strong>Add Intro?</strong>
                        </p>
                      </div>
                      <Checkbox
                        defaultChecked={addIntro}
                        color="default"
                        onChange={handleAddIntro}
                      />
                    </ListItem>
                    {addIntro && (
                      <div
                        // className={styles.brandKitItemRight}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px',
                        }}
                      >
                        <h3 className={styles.projectsFunctionHeading}>Intro:</h3>
                        {/* <div>
                          <input
                            type="file"
                            name="myImage"
                            accept=".png, .mp4"
                            id="fileInputIntro"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              if (event.target.files) {
                                uploadImage(event.target.files[0], 'intro');
                              }
                            }}
                          />
                          <label
                            htmlFor="fileInputIntro"
                            style={{
                              cursor: 'pointer',
                              padding: '10px',
                              border: '0px solid #ccc',
                              borderRadius: '4px',
                            }}
                          >
                            <div style={{ width: '120px', height: '50px' }}>
                              <BrandKitVideoItem
                                path={
                                  project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.intro ?? ''
                                }
                                controls
                              />
                            </div>
                          </label>
                        </div> */}
                        <div
                          style={{
                            width: '224px',
                            height: '126px',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {project.edits.find(
                            (edit) => edit.id === videoId && edit.status === 'Processed',
                          )?.overlaySettings?.intro && (
                            <BrandKitVideoItem
                              path={
                                project.edits.find(
                                  (edit) => edit.id === videoId && edit.status === 'Processed',
                                )?.overlaySettings?.intro ?? ''
                              }
                              controls
                            />
                          )}

                          <div data-testid={'file'} style={{ marginLeft: '1rem' }}>
                            <FileUpload
                              styleEl="panel"
                              fileTypes={['.png', '.mp4']}
                              onChange={(file: File) => uploadImage(file, 'intro')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <ListItem sx={{ justifyContent: 'space-between' }}>
                      <div>
                        <p className={styles.projectsFunctionHeading}>
                          <strong>Add Outro?</strong>
                        </p>
                      </div>
                      <Checkbox
                        defaultChecked={addOutro}
                        color="default"
                        onChange={handleAddOutro}
                      />
                    </ListItem>
                    {addOutro && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px',
                        }}
                      >
                        <h3 className={styles.projectsFunctionHeading}>Outro:</h3>
                        {/* <>
                          <input
                            type="file"
                            name="myImage"
                            accept=".png, .mp4"
                            id="fileInputIntro"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              if (event.target.files) {
                                uploadImage(event.target.files[0], 'outro');
                              }
                            }}
                          />
                          <label
                            htmlFor="fileInputOutro"
                            style={{
                              cursor: 'pointer',
                              padding: '10px',
                              border: '0px solid #ccc',
                              borderRadius: '4px',
                            }}
                          >
                            <div style={{ width: '150px', height: '100px' }}>
                              <BrandKitVideoItem
                                path={
                                  project.edits.find(
                                    (edit) => edit.id === videoId && edit.status === 'Processed',
                                  )?.overlaySettings?.outro ?? ''
                                }
                                controls
                              />
                            </div>
                          </label>
                        </> */}
                        <div
                          style={{
                            width: '224px',
                            height: '126px',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {project.edits.find(
                            (edit) => edit.id === videoId && edit.status === 'Processed',
                          )?.overlaySettings?.outro && (
                            <BrandKitVideoItem
                              path={
                                project.edits.find(
                                  (edit) => edit.id === videoId && edit.status === 'Processed',
                                )?.overlaySettings?.outro ?? ''
                              }
                              controls
                            />
                          )}
                          <div data-testid={'file'} style={{ marginLeft: '1rem' }}>
                            <FileUpload
                              styleEl="panel"
                              fileTypes={['.png', '.mp4']}
                              onChange={(file: File) => uploadImage(file, 'outro')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={styles.videoWrapper}>
                    <Grid container>
                      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VideoProvider video={video}>{videoComponent}</VideoProvider>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </ProjectProvider>
      )}
    </>
  );
};

export default ProjectVideo;
