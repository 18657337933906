import { FC, useRef, useState } from 'react';
import styles from './EditableLabel.module.scss';
import classNames from 'classnames';
import editIcon from '../../assets/images/edit.svg';
/* istanbul ignore file */

interface Props {
  onSave(text: string): void;
  text?: string;
  testId?: string;
  icon?: 'on' | 'off';
}
// const DEFAULT_LABEL_LENGTH = 300;
// const OFFSET_PERCENT = 0.8;

const EditableLabel: FC<Props> = ({ onSave, text, testId, icon = 'on' }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newValue, setNewValue] = useState(text);
  const [previousValue, setPreviousValue] = useState(text);
  // const [width, setWidth] = useState((text?.length || 0) + DEFAULT_LABEL_LENGTH);

  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   const { offsetWidth } = inputRef.current || { offsetWidth: 0 };
  //   const defaultLengthWithOffset = offsetWidth + offsetWidth * OFFSET_PERCENT;
  //   const offset =
  //     DEFAULT_LABEL_LENGTH < defaultLengthWithOffset
  //       ? defaultLengthWithOffset
  //       : DEFAULT_LABEL_LENGTH;

  //   setWidth(offset);
  // }, [newValue]);

  const handleClick = (): void => {
    setIsEditMode(true);
    setPreviousValue(newValue);
  };

  const saveAndClose = (text: string): void => {
    if (previousValue !== text) {
      setPreviousValue(text);
      onSave(text);
    }
    setIsEditMode(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Escape') {
      setNewValue(previousValue);
      setIsEditMode(false);
    } else if (e.key === 'Enter') {
      saveAndClose(e.currentTarget.value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.hide} ref={inputRef}>
        {newValue}
      </span>
      <input
        value={newValue}
        // style={{ width }}
        type="text"
        onClick={handleClick}
        onChange={(e) => setNewValue(e.target.value)}
        onBlur={(e) => saveAndClose(e.target.value)}
        onKeyDown={handleKeyDown}
        className={classNames({
          [styles.inputViewMode]: !isEditMode,
          [styles.inputEditMode]: isEditMode,
          [styles.show]: isEditMode,
          [styles.videoTitle]: !isEditMode,
        })}
        maxLength={200}
        data-testid={testId}
      />
      {icon === 'on' && !isEditMode && (
        <img
          src={editIcon}
          alt="edit"
          className={styles.editIcon}
          onClick={() => {
            handleClick();
            inputRef.current?.focus();
          }}
        />
      )}
    </div>
  );
};

export default EditableLabel;
