import { createContext, FC, RefObject, useContext, useEffect, useRef, useState } from 'react';

import VideoHelper from './VideoHelper';
import Timing, { TopAndTailPayload } from '../../entities/timing';
import VideoEditor from './VideoEditor';
import VideoTimelineControl from './VideoTimelineControl';

interface Props {
  url: string;
  timing?: Timing | null | undefined;
  onConfirm(timing: TopAndTailPayload): Promise<void>;
  editable?: boolean;
  submitButtonText?: string;
  inputFocus?: boolean;
  setTextForInput(key?: string): void;
  // setTextForInput?: (time: string) => void;
}

interface VideoContextValue {
  videoRef?: RefObject<HTMLVideoElement>;
  videoControls: boolean;
  videoUrl: string;
  videoDuration: number;
  videoProgress: number;
  playing: boolean;
  startTime: number;
  endTime: number;
  setStartTime(time: number): void;
  setEndTime(time: number): void;
  setCurrentTime(time: number): void;
  onMoveGrabber(time: number): void;
}

const VideoContext = createContext<VideoContextValue | null>(null);

export function useVideoContext(): VideoContextValue {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error('VideoPlayer may only be used in the context of a <Video> component');
  }

  return context;
}

const Video: FC<Props> = ({
  children,
  url,
  timing,
  onConfirm,
  editable,
  submitButtonText,
  inputFocus,
  setTextForInput,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [enhanceVideo, setEnhanceVideo] = useState(false);

  const handleToggle = (): void => {
    setEnhanceVideo(!enhanceVideo);
  };

  const handleOnSave = async (): Promise<void> => {
    try {
      setIsSaving(true);
      await onConfirm({
        end: endTime,
        start: startTime,
        enhanceVideo,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handlePlayPauseClick = (): void => {
    const videoElt = videoRef.current;
    if (!videoElt) return;

    if (isPlaying) {
      videoElt.pause();
    } else {
      if (Math.abs(videoElt.currentTime - endTime) < 0.01) {
        videoElt.currentTime = startTime;
      }

      void videoElt.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleCancelClick = (): void => {
    const videoElt = videoRef.current;

    if (!videoElt) return;

    videoElt.currentTime = 0;
    setStartTime(0);
    setEndTime(videoElt.duration || 0);
    setVideoProgress(0);
  };

  const handleMoveGrabber = (currentTime: number): void => {
    const videoElt = videoRef.current;
    if (!videoElt) return;

    videoElt.pause();
    videoElt.currentTime = currentTime;

    setIsPlaying(false);
    setVideoProgress(0);
  };

  const handleCurrentTimeChange = (newCurrentTime: number): void => {
    const videoElt = videoRef.current;
    if (!videoElt) return;

    videoElt.currentTime = newCurrentTime;
    setVideoProgress(
      VideoHelper.currentVideoProgress(videoElt.duration, videoElt.currentTime, startTime),
    );
  };

  useEffect(
    () => {
      const videoElt = videoRef.current;

      if (!videoElt) return;
      videoElt.onloadedmetadata = () => {
        setStartTime(timing?.start ?? 0);
        setEndTime(timing?.end ?? videoElt.duration);

        setVideoDuration(videoElt.duration);
      };

      setVideoProgress(
        VideoHelper.currentVideoProgress(videoElt.duration, videoElt.currentTime, startTime),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const videoElt = videoRef.current;

    const handleTimeUpdate = (): void => {
      if (!videoElt || !isPlaying) return;
      setVideoProgress(
        VideoHelper.currentVideoProgress(videoElt.duration, videoElt.currentTime, startTime),
      );

      if (videoElt.currentTime >= endTime) {
        videoElt.pause();
        videoElt.currentTime = endTime;
        setIsPlaying(false);
      }
    };

    videoElt?.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElt?.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [startTime, endTime, isPlaying]);

  // useEffect(() => {
  //   const handleKeyDown = (e: KeyboardEvent): void => {
  //     const isInputField =
  //       (e.target as HTMLElement).tagName === 'INPUT' ||
  //       (e.target as HTMLElement).tagName === 'TEXTAREA';
  //     if (e.key === ' ' && videoRef.current && !isInputField) {
  //       e.preventDefault();
  //       if (videoRef.current.paused) {
  //         (function (): void {
  //           // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  //           if (videoRef.current) {
  //             videoRef.current
  //               .play()
  //               .then(() => {
  //                 console.log('video played');
  //               })
  //               .catch(() => {
  //                 console.log('error while playing video');
  //               });
  //           }
  //         })();
  //       } else {
  //         videoRef.current.pause();
  //       }
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);

  //   // Don't forget to clean up
  //   return function cleanup() {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  return (
    <VideoContext.Provider
      value={{
        videoControls: !editable,
        videoUrl: url,
        videoRef,
        videoDuration,
        videoProgress,
        playing: isPlaying,
        startTime,
        endTime,
        setStartTime,
        setEndTime,
        setCurrentTime: handleCurrentTimeChange,
        onMoveGrabber: handleMoveGrabber,
      }}
    >
      {children}

      {editable && (
        <>
          <VideoTimelineControl />
          <VideoEditor
            onCancel={handleCancelClick}
            onSave={handleOnSave}
            isSaving={isSaving}
            onToggle={handleToggle}
            onPlayPause={handlePlayPauseClick}
          />
        </>
      )}
    </VideoContext.Provider>
  );
};

export default Video;
