import React, { FC, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './Plans.module.scss';
import PrimaryButton from '@/components/common/PrimaryButton';
// import { useCancelSubscription, useUpdateSubscription } from '@/adapters/subscription';
// import { useAuth0 } from '@auth0/auth0-react';
// import { CHECKOUT_URL, MAIN_URL } from '@/routePaths';
// import { UseMutateAsyncFunction } from 'react-query';
// import {
//   CancelSubscriptionResponse,
//   UpdateSubscriptionResponse,
//   createSubscriptionReq,
// } from '@/entities/subscription';
// import { UserContext } from '@/contexts/UserContext';
import { Typography, Grid } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { SUBSCRIPTION_HISTORY_URL, SUBSCRIPTION_PLANS_URL } from '@/routePaths';
import CheckoutModal from '@/components/modals/CheckoutModal';
import PaymentSuccessModal from '@/components/modals/PaymentSuccessModal';

/* istanbul ignore file */
const CheckedTypography: FC = ({ children }) => {
  return (
    <div className={styles.checkedTypography}>
      <CheckCircleOutlineOutlinedIcon fontSize="small" className={styles.checkCircleIcon} />
      <Typography className={styles.featureContent} variant="body1">
        {children}
      </Typography>
    </div>
  );
};

const Plans: FC = () => {
  const [isAnnualMode, setIsAnnualMode] = useState<boolean>(false);
  const [isPlanPage, setIsPlanPage] = useState<boolean>(false);
  const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [keyError, setKeyError] = useState<string | undefined | null>(null);

  const handleCheckoutModalClose: () => void = () => {
    setOpenCheckoutModal(false);
  };

  const handleSuccessModalClose: () => void = () => {
    setOpenSuccessModal(false);
  };

  const location = useLocation();
  const currentUrl = location.pathname;

  useEffect(() => {
    if (currentUrl.includes('plans')) {
      setIsPlanPage(true);
    }
  }, [currentUrl]);

  // const { setUserState } = useContext(UserContext);
  // const isFreePlan: boolean = userState?.plan === '';
  // const isProPlan: boolean = userState?.plan === 'pro' && userState.state;

  // const [activePlan, setActivePlan] = useState<string>('');

  // const navigate = useNavigate();

  // const { userState, setUserState } = useContext(UserContext);
  // const plan = userState?.plan;

  // const handleCreateSubscription = (plan: string): void => {
  //   navigate(CHECKOUT_URL, { state: { plan: plan } });
  // };

  // const {
  //   mutateAsync: cancelSubscription,
  // }: {
  //   mutateAsync: UseMutateAsyncFunction<
  //     CancelSubscriptionResponse,
  //     unknown,
  //     createSubscriptionReq,
  //     unknown
  //   >;
  // } = useCancelSubscription();
  // const handleCancelSubscription = async (plan: string): Promise<void> => {
  //   const { canceled }: { canceled: string } = await cancelSubscription({
  //     email: email,
  //     lookup_key: plan,
  //   });

  //   if (canceled) {
  //     setUserState &&
  //       setUserState({
  //         plan: '',
  //         trialDays: 999,
  //         state: false,
  //       });

  //     navigate(MAIN_URL);
  //   }
  // };

  // useEffect(() => {
  //   if (plan) {
  //     setActivePlan(plan);
  //   }
  // }, [plan]);

  // const SubscriptionButton: (props: { plan: string }) => JSX.Element = (props) => {
  //   const plan = props.plan;
  //   const buttonLabel = activePlan !== '' ? (activePlan === plan ? 'CANCEL' : 'UPDATE') : 'BUY';
  //   return (
  //     <PrimaryButton
  //       size="medium"
  //       onClick={() =>
  //         activePlan !== ''
  //           ? activePlan === plan
  //             ? handleCancelSubscription(plan)
  //             : handleUpdateSubscription(plan)
  //           : handleCreateSubscription(plan)
  //       }
  //     >
  //       {buttonLabel}
  //     </PrimaryButton>
  //   );
  // };

  useEffect(() => {
    const err = localStorage.getItem('keyError');
    if (err) {
      setKeyError(err);
      setTimeout(() => setKeyError(null), 10000);
    }
  }, []);

  return (
    <>
      {keyError && (
        <p
          style={{
            fontWeight: 'bold',
            color: 'red',
            fontSize: '20px',
          }}
        >
          {keyError}
        </p>
      )}
      <div className={styles.subscription}>
        <Typography className={styles.subscriptionHeadTitle} variant="h4" fontWeight={'bold'}>
          Order History
        </Typography>
        <div className={styles.subscriptionTabs}>
          <Link to={SUBSCRIPTION_HISTORY_URL}>
            <Typography
              className={styles.subscriptionTab + (isPlanPage ? '' : ' ' + styles.activeTab)}
              variant="body2"
              fontWeight={'bold'}
              color={'var(--navy-blue)'}
            >
              Order History
            </Typography>
          </Link>
          <Link to={SUBSCRIPTION_PLANS_URL}>
            <Typography
              className={styles.subscriptionTab + (!isPlanPage ? '' : ' ' + styles.activeTab)}
              variant="body2"
              fontWeight={'bold'}
              color={'var(--navy-blue)'}
            >
              Plan
            </Typography>
          </Link>
        </div>
        <div className={styles.paymentModeSwitch}>
          <div className={styles.paymentModes}>
            <div
              className={styles.paymentMode + (isAnnualMode ? '' : ' ' + styles.activeMode)}
              onClick={() => setIsAnnualMode(false)}
            >
              Monthly
            </div>
            <div
              className={styles.paymentMode + (isAnnualMode ? ' ' + styles.activeMode : '')}
              onClick={() => setIsAnnualMode(true)}
            >
              Annually
            </div>
          </div>
          <div className={styles.annualDiscount}>Save 20%</div>
        </div>
        <Grid container spacing={2} className={styles.subscriptionPlans}>
          <Grid item sx={{ display: 'flex' }} xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className={styles.planContainer}>
              <div className={styles.planBox}>
                <div className={styles.planTitle}>
                  <Typography className={styles.mainTitle} variant="h4" fontWeight={'bold'}>
                    FREE
                  </Typography>
                  <Typography className={styles.subTitle} variant="body1">
                    Always free!
                  </Typography>
                </div>
                <div className={styles.features}>
                  <CheckedTypography>AI Top and Tail editing trimming tool</CheckedTypography>
                  <CheckedTypography>Branding Kit: Add intro/outro (png/mp4)</CheckedTypography>
                  <CheckedTypography>Automatic Editable Transcription</CheckedTypography>
                  <CheckedTypography>Free Zoom Integration + Streaming Server</CheckedTypography>
                  <CheckedTypography>Download and Share your Videos in full HD</CheckedTypography>
                </div>
                <PrimaryButton
                  className={styles.purchaseButton + styles.disabledButton}
                  // className={styles.purchaseButton + (true ? ' ' + styles.disabledButton : '')}
                  disabled={true}
                >
                  <Typography
                    className={styles.purchaseButtonLabel}
                    variant="body2"
                    fontWeight={'bold'}
                  >
                    Selected
                  </Typography>
                </PrimaryButton>
              </div>
            </div>
          </Grid>
          <Grid item sx={{ display: 'flex' }} xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className={styles.planContainer}>
              <div className={styles.popularBadge}>
                <Typography className={styles.popularBadgeLabel} fontWeight={'bold'}>
                  Most Popular
                </Typography>
              </div>
              <div className={styles.proPlanBox}>
                <div className={styles.planTitle}>
                  <Typography className={styles.mainTitle} variant="h4" fontWeight={'bold'}>
                    PRO - {isAnnualMode ? 'USD$480/year' : 'USD$49.95/month'}
                  </Typography>
                  <Typography className={styles.subTitle} variant="body1">
                    Create segments and branded clips!
                  </Typography>
                </div>
                <div className={styles.features}>
                  <Typography className={styles.allFreeFeatures}>
                    Everything in the Free Plan +
                  </Typography>
                  <CheckedTypography>Create multiple segments per video</CheckedTypography>
                  <CheckedTypography>Upload videos (feature unlocked)</CheckedTypography>
                  <CheckedTypography>Add branding overlays (Logo / Titles)</CheckedTypography>
                  <CheckedTypography>
                    Cloud Storage and Social Media Integrations (upon request)
                  </CheckedTypography>
                </div>
                <PrimaryButton
                  className={styles.purchaseButton}
                  // className={styles.purchaseButton + (isProPlan ? ' ' + styles.disabledButton : '')}
                  onClick={() => setOpenCheckoutModal(true)}
                >
                  <Typography
                    className={styles.purchaseButtonLabel}
                    variant="body2"
                    fontWeight={'bold'}
                  >
                    Get PRO
                  </Typography>
                </PrimaryButton>
              </div>
            </div>
          </Grid>
          <Grid item sx={{ display: 'flex' }} xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className={styles.planContainer}>
              <div className={styles.planBox}>
                <div className={styles.planTitle}>
                  <Typography className={styles.mainTitle} variant="h4" fontWeight={'bold'}>
                    ENTERPRISE
                  </Typography>
                  <Typography className={styles.subTitle} variant="body1">
                    Custom Integration & Multi-Seat License
                  </Typography>
                </div>
                <div className={styles.features}>
                  <CheckedTypography>Add Multiple streaming servers (RTMP/SRT)</CheckedTypography>
                  <CheckedTypography>
                    Add cloud-to-cloud custom outputs (ie. AWS S3, Azure Storage, Dropbox, etc)
                  </CheckedTypography>
                  <CheckedTypography>Request features to Tech Roadmap</CheckedTypography>
                  <CheckedTypography>Personalised account manager</CheckedTypography>
                  <CheckedTypography>Enterprise Security and Privacy Compliance</CheckedTypography>
                </div>
                <PrimaryButton className={styles.purchaseButton}>
                  <Typography
                    className={styles.purchaseButtonLabel}
                    variant="body2"
                    fontWeight={'bold'}
                  >
                    Contact Sales Team
                  </Typography>
                </PrimaryButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <CheckoutModal
        open={openCheckoutModal}
        isAnnualModeInit={isAnnualMode}
        handleClose={handleCheckoutModalClose}
        setIsAnnualMode={setIsAnnualMode}
      />
      <PaymentSuccessModal open={openSuccessModal} handleClose={handleSuccessModalClose} />
    </>
  );
};

export default Plans;
