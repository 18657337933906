import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const ClipGridHeader: React.FC = () => {
  return (
    <Box
      paddingX={3}
      height={50}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Grid container spacing={2} width="100%" marginBottom={1}>
        <Grid item style={{ paddingLeft: 0 }} xs={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            File Name
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 0 }} xs={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Start Time
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 0 }} xs={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            End Time
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClipGridHeader;
