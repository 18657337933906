import { FC } from 'react';
import { useVideoContext } from './Video';

interface VideoPlayerProps {
  videoSize?: string;
  headingTitle?: string;
  headingStyle?: any;
  headingContainerStyle?: any;
  logo?: string;
  imageAtr?: {
    image?: Blob;
    position?: any;
  };
  addLogo?: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  // videoSize,
  headingTitle,
  headingStyle,
  headingContainerStyle,
  logo,
  imageAtr,
  addLogo,
}) => {
  const context = useVideoContext();

  const { videoRef, videoControls, videoUrl } = context;

  // const img = imageAtr?.image ? URL.createObjectURL(imageAtr.image) : '';

  // const sizeObj = useMemo(() => {
  //   switch (videoSize) {
  //     case '1:1':
  //       return {
  //         marginHorizontal: '0 25% 5% 25%',
  //       };
  //     case '16:9':
  //       return {
  //         marginHorizontal: '0',
  //       };
  //     case '9:16':
  //       return {
  //         marginHorizontal: '0 30% 3% 30%',
  //       };
  //     default:
  //       return {
  //         marginHorizontal: '0',
  //       };
  //   }
  // }, [videoSize]);

  return (
    <div>
      <div style={headingContainerStyle}>
        <div style={headingStyle}>{headingTitle}</div>
      </div>
      {/* {addLogo && (
        <>
          {(imageAtr && imageAtr.image && imageAtr.image.size !== 0) || logo ? (
            <img
              src={logo ?? img}
              alt="image"
              style={{ ...imageAtr?.position, imageRendering: 'pixelated' }}
            />
          ) : (
            <></>
          )}
        </>
      )} */}
      <video
        controls={videoControls}
        playsInline
        ref={videoRef}
        style={
          {
            // height: 255,
            // padding: sizeObj.marginHorizontal,
            // objectFit: 'cover',
          }
        }
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
