import React, { FC, useContext } from 'react';
import { Box, Button, Menu, MenuItem, Stack } from '@mui/material';
import { TranscriptContext } from '@/contexts/TranscriptContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TranscriptToolbar: FC<{ disablePlay: boolean; setDisablePlay(val: boolean): void }> = ({
  disablePlay,
  setDisablePlay,
}) => {
  const {
    editable,
    edit,
    save,
    cancel,
    undo,
    redo,
    exportToTXT,
    exportToVTT,
    isLoadingTranscript,
    isUpdatingProjectVideoTranscript,
  } = useContext(TranscriptContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const canEdit = true;

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleExport = (exportFunc: () => void): (() => void) => {
    return () => {
      exportFunc();
      handleClose();
    };
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        marginBottom: 2,
        '@media (max-width:600px)': {
          marginTop: 0,
          marginBottom: 0,
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        {/* {canEdit && (
          <Button variant="contained" onClick={edit}>
            Edit
          </Button>
        )} */}
        <Button
          variant="contained"
          onClick={() => {
            setDisablePlay(true);
            edit();
          }}
        >
          Edit
        </Button>
        {editable && (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setDisablePlay(false);
              save();
              cancel();
            }}
            disabled={isLoadingTranscript || isUpdatingProjectVideoTranscript}
          >
            Save
          </Button>
        )}
        {editable && (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setDisablePlay(false);
                cancel();
              }}
              disabled={isLoadingTranscript || isUpdatingProjectVideoTranscript}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              onClick={undo}
              disabled={isLoadingTranscript || isUpdatingProjectVideoTranscript}
            >
              Undo
            </Button>

            <Button
              variant="outlined"
              onClick={redo}
              disabled={isLoadingTranscript || isUpdatingProjectVideoTranscript}
            >
              Redo
            </Button>
          </>
        )}

        {!editable && (
          <Button
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={isLoadingTranscript || isUpdatingProjectVideoTranscript}
          >
            Export
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleExport(exportToTXT)}>TXT</MenuItem>
          <MenuItem onClick={handleExport(exportToVTT)}>VTT</MenuItem>
        </Menu>
      </Stack>
    </Box>
  );
};

export default TranscriptToolbar;
