import React, { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import Loading from '@/components/Loading';
import { Link, useParams } from 'react-router-dom';
import { useGetProject } from '@/adapters/projects';
import styles from '@/pages/ProjectVideo/ProjectVideo.module.scss';
import { PROJECTS_URL } from '@/routePaths';
import leftSlackIcon from '@/assets/images/left-slack.svg';
import Grid from '@mui/material/Unstable_Grid2';
import Transcript from '@/components/Transcript';
import TranscriptToolbar from '@/components/Transcript/TranscriptToolbar';
import { TranscriptProvider } from '@/contexts/TranscriptContext';
import { Box } from '@mui/system';
import { ProjectProvider } from '@/contexts/ProjectContext';
const ProjectVideoTranscript: FC = () => {
  const { projectId, videoId = 'original' } = useParams<{ projectId: string; videoId: string }>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [disablePlay, setDisablePlay] = useState<boolean>(false);
  const { data: project, isLoading } = useGetProject(projectId!);

  const [videoCurrentTime, setVideoCurrentTime] = useState<number | null>(null);
  const [videoPath, setVideoPath] = useState<string>('');
  const [timeCodes, setTimeCodes] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });
  const onTimeUpdate = useCallback((event: SyntheticEvent<HTMLVideoElement, Event>): void => {
    setVideoCurrentTime(event.currentTarget.currentTime);
  }, []);

  useEffect(() => {
    if (project) {
      const pathArray = window.location.pathname.split('/');
      const segmentId = pathArray[pathArray.length - 1];
      const segment = project.edits.filter((seg) => seg.id === segmentId);
      setVideoPath(segment[0].fullPath ?? '');
      setTimeCodes({
        start: segment[0].timing.start,
        end: segment[0].timing.end,
      });
    }
  }, [project]);

  return (
    <>
      {isLoading || !project ? (
        <Loading />
      ) : (
        <ProjectProvider project={project}>
          <TranscriptProvider
            containerRef={containerRef}
            project={project}
            currentTime={videoCurrentTime}
            videoRef={videoRef}
            videoId={videoId}
            seg={true}
            timeCodes={timeCodes}
          >
            <div className={styles.projectDetailInner}>
              <div className={styles.routePaths}>
                <div>
                  <Link to={PROJECTS_URL} className={`${styles.burgerContentItem} projects`}>
                    Projects
                  </Link>
                </div>
                <div className={styles.leftSlack}>
                  <img src={leftSlackIcon} alt="left" />
                </div>
                <div className={styles.currentProjectName}>{project.name}</div>
                <div className={styles.leftSlack}>
                  <img src={leftSlackIcon} alt="left" />
                </div>
                <div>Transcript</div>
              </div>
              <div className={styles.projectDetailHeader}>
                <TranscriptToolbar disablePlay={disablePlay} setDisablePlay={setDisablePlay} />
              </div>
              <Box sx={{}} ref={containerRef}>
                <Grid container columnSpacing={{}} justifyContent={'space-between'}>
                  <Grid xs={6}>
                    <Transcript />
                  </Grid>
                  <Grid xs={5}>
                    <video
                      controls={true}
                      playsInline
                      className={styles.video}
                      ref={videoRef}
                      onTimeUpdate={onTimeUpdate}
                    >
                      <source src={videoPath} type="video/mp4" />
                    </video>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </TranscriptProvider>
        </ProjectProvider>
      )}
    </>
  );
};

export default ProjectVideoTranscript;
