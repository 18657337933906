import React, { FC, useContext, useState } from 'react';
import Api from '../../adapters/api';
import { ButtonsContainer } from '@/pages/ProjectVideo/styles';
import DefaultButton from '@/components/common/DefaultButton';
import PrimaryButton from '@/components/common/PrimaryButton';
import ShareDialog from '@/pages/ProjectVideo/ShareDialog/ShareDialog';
import { Box, CircularProgress } from '@mui/material';
import { ProjectContext } from '@/contexts/ProjectContext';
import { generatePath, useNavigate } from 'react-router-dom';
import { PROJECT_TRANSCRIPT_URL, PROJECT_VIDEO_TRANSCRIPT_URL } from '@/routePaths';
import { saveAs } from 'file-saver';
import WowzaUploadDialog from '@/pages/ProjectVideo/WowzaUploadDialog';
import { useVideoContext } from '@/components/Video/Video';
import JwpUploadDialog from '@/pages/ProjectVideo/JwpUploadingDialog';

interface VideoActionsProps {
  wowza?: boolean;
  jwp?: boolean;
  saveChanges(): void;
  onRevertToOriginal(): void;
  status?: boolean;
  disabledRevert?: boolean;
}

const VideoActions: FC<VideoActionsProps> = ({
  wowza,
  jwp,
  saveChanges,
  onRevertToOriginal,
  disabledRevert,
  status,
}) => {
  const { project } = useContext(ProjectContext);
  const { videoUrl } = useVideoContext();
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
  const [isWowzaUploadDialogOpen, setIsWowzaUploadDialogOpen] = useState<boolean>(false);
  const [isJwpUploadDialogOpen, setIsJwpUploadDialogOpen] = useState<boolean>(false);

  const viewTranscript = (): void => {
    if (!project) {
      return;
    }
    const videoId = null;

    const pathArray = window.location.pathname.split('/');
    const segId = pathArray[pathArray.length - 1];
    if (videoId) {
      navigate(
        generatePath(PROJECT_VIDEO_TRANSCRIPT_URL, {
          projectId: project.id,
          videoId,
          segmentId: segId,
        }),
      );
    } else {
      navigate(generatePath(PROJECT_TRANSCRIPT_URL, { projectId: project.id, segId }));
    }
  };

  const share = (): void => {
    setIsShareDialogOpen(true);
  };

  const download = async (): Promise<void> => {
    if (!project) {
      return;
    }

    // const fileName = project.name;
    setIsDownloading(true);
    try {
      await Api.getFile(videoUrl, (data) => {
        const arr = window.location.pathname.split('/');
        const length = arr.length;
        const name = arr[length - 1];
        saveAs(data, `${name}.mp4`);
      });
    } catch (e) {
      ///
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box>
      <ButtonsContainer>
        <DefaultButton size="small" rounded onClick={onRevertToOriginal} disabled={disabledRevert}>
          Revert to Original
        </DefaultButton>
        {status ? (
          <div style={{ display: 'flex', alignItems: 'center', margin: '12px' }}>
            <CircularProgress size={16} sx={{ marginRight: 1 }} /> Processing
          </div>
        ) : (
          <PrimaryButton size="small" rounded onClick={saveChanges}>
            {'Save Changes'}
          </PrimaryButton>
        )}
        <DefaultButton size="small" rounded onClick={viewTranscript}>
          Transcript
        </DefaultButton>
        <DefaultButton size="small" rounded onClick={share}>
          Share
        </DefaultButton>
        <PrimaryButton size="small" rounded onClick={download} disabled={isDownloading}>
          Download &nbsp; {isDownloading && <CircularProgress color="secondary" size={20} />}
        </PrimaryButton>
      </ButtonsContainer>
      <ShareDialog
        open={isShareDialogOpen}
        wowza={wowza}
        jwp={jwp}
        onShareByWowza={() => {
          setIsShareDialogOpen(false);
          setIsWowzaUploadDialogOpen(true);
        }}
        onShareByJwp={() => {
          setIsShareDialogOpen(false);
          setIsJwpUploadDialogOpen(true);
        }}
        onClose={() => setIsShareDialogOpen(false)}
      ></ShareDialog>
      <WowzaUploadDialog
        open={isWowzaUploadDialogOpen}
        onClose={() => setIsWowzaUploadDialogOpen(false)}
      ></WowzaUploadDialog>
      <JwpUploadDialog
        open={isJwpUploadDialogOpen}
        onClose={() => setIsJwpUploadDialogOpen(false)}
      ></JwpUploadDialog>
    </Box>
  );
};

export default VideoActions;
