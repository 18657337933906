import { FC, useState } from 'react';
import styles from './CancelModal.module.scss';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FiberManualRecord } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from '@/components/common/PrimaryButton';

/* istanbul ignore file */
interface Props {
  open: boolean;
  handleClose(): void;
  handleCancel(): Promise<void>;
  date: number;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  minWidth: '300px',
  maxWidth: '700px',
  bgcolor: 'var(--white)',
};

const CancelModal: FC<Props> = ({ open, handleClose, handleCancel, date }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChecked(event.target.checked);
  };

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose} disableScrollLock>
      <Box sx={style}>
        <div className={styles.cancelModal}>
          <Typography className={styles.cancelModalTitle}>Cancel Subscription</Typography>
          <div className={styles.closeMark} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </div>
          <div className={styles.content}>
            <Typography className={styles.notation}>
              If you are sure you’d like to cancel your subscription, please note the following and
              confirm below.{' '}
            </Typography>
            <div className={styles.notationList}>
              <div className={styles.listItem}>
                <FiberManualRecord fontSize="small" />
                <Typography className={styles.content}>
                  Your Pro Plan will remain active until
                  <span className={styles.untilDate}>
                    {' '}
                    {new Date(date * 1000).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}{' '}
                  </span>
                  and after you will no longer be charged.
                </Typography>
              </div>
              <div className={styles.listItem}>
                <FiberManualRecord fontSize="small" />
                <Typography className={styles.content}>
                  Cancelling will downgrade your package to the free plan removing all Pro package
                  features.
                </Typography>
              </div>
            </div>
            <div className={styles.checkUnderstanding}>
              <Checkbox
                className={styles.checkbox}
                checked={checked}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 32 },
                }}
                onChange={handleChange}
              />
              <Typography className={styles.understandNotaion}>
                I understand and would like to proceed with cancellation...
              </Typography>
            </div>
          </div>
          <div className={styles.buttons}>
            <PrimaryButton className={styles.backButton} onClick={handleClose}>
              <Typography className={styles.buttonLabel + ' ' + styles.backButtonLabel}>
                Back
              </Typography>
            </PrimaryButton>
            <PrimaryButton
              className={styles.confirmButton + (checked ? '' : ' ' + styles.disabledButton)}
              disabled={!checked}
              onClick={handleCancel}
            >
              <Typography className={styles.buttonLabel + ' ' + styles.backButtonLabel}>
                Yes, Cancel Subscription
              </Typography>
            </PrimaryButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CancelModal;
