import React, { FC, useEffect, useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './OrderHistory.module.scss';
import PrimaryButton from '@/components/common/PrimaryButton';
import {
  useGetOrderHistory,
  useCancelSubscription,
  useCancelReason,
} from '@/adapters/subscription';
import { useAuth0 } from '@auth0/auth0-react';
import {
  GetOrderHistoryResponse,
  OrderHistoryInfo,
  CurrentSubscription,
  CancelSubscriptionResponse,
} from '@/entities/subscription';
import { UserContext } from '@/contexts/UserContext';
import { Typography, Grid } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { SUBSCRIPTION_HISTORY_URL, SUBSCRIPTION_PLANS_URL } from '@/routePaths';
import CancelModal from '@/components/modals/CancelModal';
import ConfirmCancelModal from '@/components/modals/ConfirmCancelModal';
import Loading from '@/components/Loading';

/* istanbul ignore file */

{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}

interface PanelProps {
  currentSubscription: CurrentSubscription | undefined;
}

interface TableRowProps {
  historyInfo: OrderHistoryInfo;
  index: number;
}

const CurrentPlanPanels: FC<PanelProps> = ({ currentSubscription }) => {
  const startDate = new Date(
    currentSubscription?.start_date ? currentSubscription.start_date * 1000 : '',
  );
  const startDateString = startDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const endDate = new Date(
    currentSubscription?.next_billing_date ? currentSubscription.next_billing_date * 1000 : '',
  );
  const endDateString = endDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <>
      <Grid container spacing={4} className={styles.curPlanPanels}>
        <Grid item xs={6} sm={6} lg={3}>
          <div className={styles.planInfoPanel}>
            <Typography className={styles.panelTitle}>Current Plan:</Typography>
            <Typography className={styles.panelContent}>
              {currentSubscription?.curPlan ?? 'Free Plan'}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <div className={styles.planInfoPanel}>
            <Typography className={styles.panelTitle}>Price:</Typography>
            <Typography className={styles.panelContent}>
              {currentSubscription?.price ?? '—'}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <div className={styles.planInfoPanel}>
            <Typography className={styles.panelTitle}>Start Date:</Typography>
            <Typography className={styles.panelContent}>
              {currentSubscription?.start_date ? startDateString : '—'}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <div className={styles.planInfoPanel}>
            <Typography className={styles.panelTitle}>Next Billing Date:</Typography>
            <Typography className={styles.panelContent}>
              {currentSubscription?.next_billing_date ? endDateString : '—'}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const TableRow: FC<TableRowProps> = ({ historyInfo, index }) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const date = new Date(historyInfo.date * 1000);
  const dateString = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  return (
    <>
      <div className={styles.tableRow}>
        <div className={styles.tableItem}>{index}</div>
        <div className={styles.tableItem}>{dateString}</div>
        <div className={styles.tableItem}>{historyInfo.status}</div>
        <div className={styles.tableItem}>{historyInfo.payment}</div>
        <div className={styles.tableItem}>
          {' '}
          <Typography className={styles.itemContent}>
            {'$' + (+historyInfo.order_total / 100).toFixed(2)}
          </Typography>
        </div>
        <div className={styles.tableItem + ' ' + styles.containAction}>
          <div className={styles.expandLink} onClick={() => setIsExpand(!isExpand)}>
            <Typography className={styles.itemContent}>
              View
              {isExpand ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </Typography>
          </div>
        </div>
      </div>
      {isExpand && (
        <div className={styles.tableSubRow}>
          <div className={styles.tableItem}>
            <Typography className={styles.itemContent} color={'var(--boulder)'}>
              Order number
            </Typography>
            <Typography className={styles.itemContent}>{index}</Typography>
          </div>
          <div className={styles.tableItem}>
            <Typography className={styles.itemContent} color={'var(--boulder)'}>
              Payment Method
            </Typography>
            <Typography className={styles.itemContent}>{historyInfo.payment_method}</Typography>
          </div>
          <div className={styles.tableItem}>
            <Typography className={styles.itemContent} color={'var(--boulder)'}>
              Plans
            </Typography>
            <Typography className={styles.itemContent}>
              {historyInfo.plan ? historyInfo.plan : 'Additional Stream Key'}
            </Typography>
          </div>
          <div className={styles.tableItem}>
            <Typography className={styles.itemContent} color={'var(--boulder)'}>
              Email
            </Typography>
            <Typography className={styles.itemContent}>{historyInfo.email}</Typography>
          </div>
          <div
            className={styles.tableItem + ' ' + styles.pdfDownload}
            onClick={() => window.open(historyInfo.download_receipt, '_blank')}
          >
            <DownloadIcon />
            <Typography className={styles.itemContent}>Download PDF Receipt</Typography>
          </div>
        </div>
      )}
    </>
  );
};

const Plans: FC = () => {
  const [isPlanPage, setIsPlanPage] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState<boolean>(false);
  const [orderHistoryInfo, setOrderHistoryInfo] = useState<GetOrderHistoryResponse | null>(null);
  const [canceledInfo, setCanceledInfo] = useState<CancelSubscriptionResponse | null>(null);

  const { user } = useAuth0();
  const {
    data: orderHistoryResponse,
    isLoading,
  }: { data: GetOrderHistoryResponse | undefined; isLoading: boolean } = useGetOrderHistory(
    user?.sub ?? '',
  );

  useEffect(() => {
    if (orderHistoryResponse) {
      setOrderHistoryInfo(orderHistoryResponse);
    }
  }, [orderHistoryResponse]);

  const { mutateAsync: cancelSubscription } = useCancelSubscription();
  const handleCancelSubscripton: () => Promise<void> = async () => {
    const canceledInfo: CancelSubscriptionResponse = await cancelSubscription({
      email: user?.email ?? '',
    });
    if (canceledInfo) {
      setOpenCancelModal(false);
      setOpenConfirmCancelModal(true);
      setCanceledInfo(canceledInfo);
    } else {
      setOpenCancelModal(false);
    }
  };

  useEffect(() => {
    if (canceledInfo?.canceled_id !== (undefined && null && '')) {
      setOpenConfirmCancelModal(true);
    } else {
      setOpenConfirmCancelModal(false);
    }
  }, [canceledInfo]);

  useEffect(() => {
    if (openConfirmCancelModal) {
      setCanceledInfo(null);
    }
  }, [openConfirmCancelModal]);

  const { mutateAsync: cancelReason } = useCancelReason();
  const handleCancelReason: (reason: string) => Promise<void> = async (reason) => {
    await cancelReason({ canceled_id: canceledInfo?.canceled_id || '', reason: reason });
    window.location.reload();
  };

  const handleCancelModalClose: () => void = () => {
    setOpenCancelModal(false);
  };

  const handleConfirmCancelModalClose: () => void = () => {
    setOpenConfirmCancelModal(false);
  };

  const location = useLocation();
  const currentUrl = location.pathname;

  useEffect(() => {
    if (currentUrl.includes('plans')) {
      setIsPlanPage(true);
    }
  }, [currentUrl]);

  const { userState } = useContext(UserContext);
  const state =
    userState && (userState.SubscriptionState as any[]).length > 0
      ? userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        )
      : [];
  const isPurchased = state.length > 0 && state[0].state ? true : false;

  return (
    <>
      <div className={styles.subscription}>
        <Typography className={styles.subscriptionHeadTitle} variant="h4" fontWeight={'bold'}>
          Order History
        </Typography>
        <div className={styles.subscriptionTabs}>
          <Link to={SUBSCRIPTION_HISTORY_URL}>
            <Typography
              className={styles.subscriptionTab + (isPlanPage ? '' : ' ' + styles.activeTab)}
              variant="body2"
              fontWeight={'bold'}
              color={'var(--navy-blue)'}
            >
              Order History
            </Typography>
          </Link>
          <Link to={SUBSCRIPTION_PLANS_URL}>
            <Typography
              className={styles.subscriptionTab + (!isPlanPage ? '' : ' ' + styles.activeTab)}
              variant="body2"
              fontWeight={'bold'}
              color={'var(--navy-blue)'}
            >
              Plan
            </Typography>
          </Link>
        </div>
        {isPurchased && (
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <PrimaryButton
              className={styles.cancelButton}
              onClick={() => {
                setOpenCancelModal(true);
              }}
            >
              Cancel payment
            </PrimaryButton>
          </div>
        )}
        <CurrentPlanPanels currentSubscription={orderHistoryInfo?.current_subscription} />
        <div className={styles.orderHistoryList}>
          <Typography className={styles.tableName}>Previous Subscriptions</Typography>
          <div className={styles.table}>
            <div className={styles.tableTitle}>
              <div className={styles.tableItem}>Reference Number</div>
              <div className={styles.tableItem}>Date</div>
              <div className={styles.tableItem}>Status</div>
              <div className={styles.tableItem}>Payment</div>
              <div className={styles.tableItem}>Order Total</div>
              <div className={styles.tableItem}>Receipt</div>
            </div>
            <div className={styles.tableBody}>
              {isLoading ? (
                <Loading />
              ) : (
                orderHistoryInfo?.data &&
                orderHistoryInfo.data.length > 0 &&
                orderHistoryInfo.data.sort((a, b) => a.date - b.date) &&
                orderHistoryInfo.data.map((info: OrderHistoryInfo, index: number) => {
                  return <TableRow historyInfo={info} index={index + 1} key={index} />;
                })
              )}
            </div>
          </div>
        </div>
      </div>
      {userState && isPurchased && (
        <CancelModal
          open={openCancelModal}
          date={
            userState.SubscriptionState.filter(
              (sub) =>
                sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
                sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
            )[0].next_billing_date
          }
          handleClose={handleCancelModalClose}
          handleCancel={handleCancelSubscripton}
        />
      )}
      <ConfirmCancelModal
        open={openConfirmCancelModal}
        handleClose={handleConfirmCancelModalClose}
        handleCancelReason={handleCancelReason}
      />
    </>
  );
};

export default Plans;
