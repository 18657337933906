import { FC, ReactNode } from 'react';
import styles from './CopyToClipboard.module.scss';

import copyIcon from '../../assets/images/copy.svg';

interface CopyToClipboardProps {
  children: ReactNode;
  text: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, children }: CopyToClipboardProps) => {
  return (
    <>
      {children}
      <img
        className={styles.copy}
        src={copyIcon}
        alt="Copy"
        onClick={() => navigator.clipboard.writeText(text)}
      />
    </>
  );
};

export default CopyToClipboard;
