/* istanbul ignore file */

export const SUBSCRIPTION_URL = '/subscription';
export const SUBSCRIPTION_HISTORY_URL = `${SUBSCRIPTION_URL}/history`;
export const SUBSCRIPTION_PLANS_URL = `${SUBSCRIPTION_URL}/plans`;
export const CHECKOUT_URL = '/checkout/subscription';
export const PROJECTS_URL = '/projects';
export const PROJECT_URL = `${PROJECTS_URL}/:projectId`;
export const PROJECT_TRANSCRIPT_URL = `${PROJECTS_URL}/:projectId/transcript/:segId`;
export const PROJECT_VIDEO_URL = `${PROJECTS_URL}/:projectId/:videoId`;
export const PROJECT_VIDEO_TRANSCRIPT_URL = `${PROJECTS_URL}/:projectId/:videoId/transcript/:segmentId`;
export const BRAND_KIT_URL = '/brand-kit';
export const STREAM_KEYS_URL = '/stream-keys';
export const INTEGRATIONS_URL = '/integrations';
export const MAIN_URL = '/';
export const BLOG_URL = 'https://editonthespot.com/blog';
export const TUTORIALS_URL = 'https://editonthespot.zendesk.com/hc/en-us';

export const USERS_URL = '/users';
export const CREATE_USER_URL = 'users/create';
export const UPDATE_USER_URL = 'users/:userId';
