import {
  useMutation,
  UseMutationResult,
  useQuery,
  // useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  CancelSubscriptionReq,
  CancelSubscriptionResponse,
  createSubscriptionReq,
  createSubscriptionResponse,
  PlansResponse,
  SubscribedCustomerInfo,
  UpdateSubscriptionResponse,
  GetOrderHistoryResponse,
  CancelReasonReq,
  CancelReasonResponse,
  createStreamSubscriptionReq,
  GetPaymentMethodResponse,
} from '../entities/subscription';
import Api from './api';

export const GET_SUBSCRIBED_CUSTOMER_INFO_CACHE_KEY = (id: string): string =>
  `subscription-customer-info-${id}`;
export const GET_ORDER_HISTORY_CACHE_KEY = (id: string): string => `order-history-${id}`;
export const GET_PAYMENT_METHOD_LIST_CACHE_KEY = (): string => `payment-method-list`;
export const GET_SUBSCRIPTION_PLANS = 'subscription-plans';

export function useGetSubscribedCustomerInfo(id: string): UseQueryResult<SubscribedCustomerInfo> {
  return useQuery(GET_SUBSCRIBED_CUSTOMER_INFO_CACHE_KEY(id), async () => {
    return await Api.get(`/subscription/custmer/${encodeURIComponent(id)}`);
  });
}

export function useGetOrderHistory(id: string): UseQueryResult<GetOrderHistoryResponse> {
  return useQuery(GET_ORDER_HISTORY_CACHE_KEY(id), async () => {
    return await Api.get(`/subscription/history/${encodeURIComponent(id)}`);
  });
}

export function useGetSubscriptionPlans(): UseQueryResult<PlansResponse> {
  return useQuery(GET_SUBSCRIPTION_PLANS, async () => {
    return await Api.get(`/subscription/plans`);
  });
}

export function useCreateSubscription(): UseMutationResult<
  createSubscriptionResponse,
  unknown,
  createSubscriptionReq
> {
  return useMutation<createSubscriptionResponse, unknown, createSubscriptionReq>(async (params) => {
    return await Api.post<createSubscriptionResponse>(
      `/subscription/create`,
      params as unknown as createSubscriptionResponse,
    );
  });
}

export function usePaymentMethod(): UseQueryResult<GetPaymentMethodResponse> {
  return useQuery(GET_PAYMENT_METHOD_LIST_CACHE_KEY(), async () => {
    return await Api.get(`customer/payment-methods`);
  });
}

export function useCreateStreamSubscription(): UseMutationResult<
  createSubscriptionResponse,
  unknown,
  createStreamSubscriptionReq
> {
  return useMutation<createSubscriptionResponse, unknown, createStreamSubscriptionReq>(
    async (params) => {
      return await Api.post<createSubscriptionResponse>(
        `/subscription/rtmp/create`,
        params as unknown as createSubscriptionResponse,
      );
    },
  );
}

export function useCancelSubscription(): UseMutationResult<
  CancelSubscriptionResponse,
  unknown,
  CancelSubscriptionReq
> {
  return useMutation<CancelSubscriptionResponse, unknown, CancelSubscriptionReq>(async (params) => {
    return await Api.post<CancelSubscriptionResponse>(
      `/subscription/cancel`,
      params as unknown as CancelSubscriptionResponse,
    );
  });
}

export function useCancelReason(): UseMutationResult<
  CancelReasonResponse,
  unknown,
  CancelReasonReq
> {
  return useMutation<CancelReasonResponse, unknown, CancelReasonReq>(async (params) => {
    return await Api.post<CancelReasonResponse>(
      `/subscription/cancel/reason`,
      params as unknown as CancelReasonResponse,
    );
  });
}

export function useUpdateSubscription(): UseMutationResult<
  UpdateSubscriptionResponse,
  unknown,
  createSubscriptionReq
> {
  return useMutation<UpdateSubscriptionResponse, unknown, createSubscriptionReq>(async (params) => {
    return await Api.post<UpdateSubscriptionResponse>(
      `/subscription/update`,
      params as unknown as UpdateSubscriptionResponse,
    );
  });
}

export function useUpdateStreamSubscription(): UseMutationResult<
  UpdateSubscriptionResponse,
  unknown,
  createStreamSubscriptionReq
> {
  return useMutation<UpdateSubscriptionResponse, unknown, createStreamSubscriptionReq>(
    async (params) => {
      return await Api.post<UpdateSubscriptionResponse>(
        `/subscription/rtmp/update`,
        params as unknown as UpdateSubscriptionResponse,
      );
    },
  );
}
