import { FC } from 'react';
import styles from './PaymentSuccessModal.module.scss';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

/* istanbul ignore file */
interface Props {
  open: boolean;
  handleClose(): void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  minWidth: '500px',
  maxWidth: '1200px',
  // height: 'calc(100% - 148px)',
  height: '700px',
  bgcolor: 'var(--white)',
  borderRadius: '34px',
  display: 'flex',
};

const CheckoutModal: FC<Props> = ({ open, handleClose }) => {
  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className={styles.modalContainer}>
          <div className={styles.closeMark} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </div>
          <div className={styles.pdfDownload}>
            <DownloadIcon fontSize="medium" />
            <Typography className={styles.pdfDownloadLabel}>Download PDF Receipt</Typography>
          </div>
          <div className={styles.content}>
            <div className={styles.circledCheck}>
              <DoneOutlinedIcon style={{ color: 'white', width: '37.5px', height: '37.5px' }} />
            </div>
            <Typography className={styles.title}>Payment Successful !</Typography>
            <Typography className={styles.description}>
              Congratulations! Your payment was successful. Check your inbox for your confirmation
              email.
            </Typography>
            <Typography className={styles.email}>samsmith@mail.com</Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CheckoutModal;
