import { BundleListItems, UserListItem } from '@/types/user-list-item';
import { useQuery, UseQueryResult } from 'react-query';
import Api from './api';

export const LIST_USERS_CACHE_KEY = 'list-users';
export const LIST_BUNDLE_ITEMS_CACHE_KEY = 'list-bundle-items';

export function useGetUserList(): UseQueryResult<UserListItem[]> {
  return useQuery(LIST_USERS_CACHE_KEY, async () => {
    return await Api.get('/users');
  });
}

export function useGetBundleItems(): UseQueryResult<BundleListItems[]> {
  return useQuery(LIST_BUNDLE_ITEMS_CACHE_KEY, async () => {
    return await Api.get('/bundleItems');
  });
}
