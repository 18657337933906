import { FC, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import Burger from '../Burger';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { SUBSCRIPTION_PLANS_URL } from '../../routePaths';
import { UserContext } from '../../contexts/UserContext';
import { UserState } from '@/entities/subscription';
import useAuthorization from '@/hooks/useAuthorization';

/* istanbul ignore file */

{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}

const Header: FC = () => {
  const [hideTrialInfo, setHideTrialInfo] = useState<boolean>(false);
  const { hasRole } = useAuthorization();
  const [subscState, setSubscState] = useState<UserState>({
    trialDays: 0,
    SubscriptionState: [],
  });
  const { userState } = useContext(UserContext);

  useEffect(() => {
    if (userState) {
      console.log('userState.trialDays', userState);
      const subscState: UserState = {
        trialDays: userState.trialDays,
        SubscriptionState: userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        ),
      };
      setSubscState(subscState);
    }
  }, [userState]);

  return (
    <header className={styles.header}>
      {!hasRole(['enterprise', 'super_admin']) &&
        (subscState.SubscriptionState as any[]).length === 0 &&
        !hideTrialInfo &&
        userState &&
        userState.bannerActive === true && (
          <div className={styles.trialInfoBar}>
            <Typography className={styles.trialInfo}>
              <span className={styles.bold}>
                {'Only ' + subscState.trialDays.toString() + ' days left'}
              </span>
              {' in your free trial. Unlock all features of Edit on the Spot.'}
              {!hasRole('temporary_enterprise') && (
                <Link to={SUBSCRIPTION_PLANS_URL} className={styles.upgradeLink}>
                  {' Upgrade now.'}
                </Link>
              )}
            </Typography>
            <div className={styles.closeIcon} onClick={() => setHideTrialInfo(true)}>
              <CloseIcon fontSize="small" />
            </div>
          </div>
        )}
      <div className={styles.uniqueIdBar}>
        {/* <Typography className={styles.uniqueId} variant="body2" color="primary">
          Unique ID: 245246
        </Typography> */}
      </div>
      <Burger />
    </header>
  );
};

export default Header;
