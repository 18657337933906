import React, { FC } from 'react';
import BaseButton, { BaseButtonProps } from '@/components/common/BaseButton/BaseButton';

type PrimaryButtonProps = BaseButtonProps;

const PrimaryButton: FC<PrimaryButtonProps> = ({ children, ...buttonProps }) => {
  return (
    <BaseButton {...buttonProps} color="primary" variant="contained">
      {children}
    </BaseButton>
  );
};

export default PrimaryButton;
