import React from 'react';
import Clip from '@/entities/clip';

export interface VideoContextValue {
  video?: Clip | undefined | null;
}

export const VideoContext = React.createContext<VideoContextValue>({});

export interface VideoProviderProps {
  video: Clip | undefined | null;
  children: React.ReactNode;
}

export const VideoProvider: React.FC<VideoProviderProps> = ({
  video,
  children,
}: VideoProviderProps) => {
  return (
    <VideoContext.Provider
      value={{
        video,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
