import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useCreateSubscription } from '@/adapters/subscription';

import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

interface StateType {
  plan: string;
}

interface StripeTypes {
  clientSecret: string;
  appearance: {
    theme: 'stripe';
    variables: {
      colorPrimary: string;
    };
  };
}

export default function Checkout(): JSX.Element {
  const [clientSecret, setClientSecret] = useState<string>('');
  const { mutateAsync: createSubscription } = useCreateSubscription();

  const location = useLocation();
  const { plan }: StateType = location.state;

  const { user } = useAuth0();
  const email = user?.email;

  useEffect(() => {
    if (plan && email) {
      // Create PaymentIntent as soon as the page loads
      const createConfig: () => Promise<void> = async () => {
        const { clientSecret }: { clientSecret: string } = await createSubscription({
          email: email, // email
          lookup_key: plan, // 'test_price_0909',
        });

        setClientSecret(clientSecret);
      };

      createConfig()
        .then(() => {
          console.log('success');
        })
        .catch(() => {
          console.log('failed');
        });
    }
  }, [email, plan, createSubscription]);

  const options: StripeTypes = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: 'primary',
      },
    },
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
