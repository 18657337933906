import React, { FC } from 'react';
import BaseButton, { BaseButtonProps } from '@/components/common/BaseButton/BaseButton';

type DefaultButtonProps = BaseButtonProps;

const DefaultButton: FC<DefaultButtonProps> = ({ children, ...buttonProps }) => {
  return (
    <BaseButton {...buttonProps} variant="outlined">
      {children}
    </BaseButton>
  );
};

export default DefaultButton;
