import React, { useCallback, useEffect, useState } from 'react';
import { useGetUploadUrl, useProcessClip } from '@/adapters/projects';
import { useUploadFile } from '@/adapters/upload';

export interface UploadContextValue {
  fileName?: string | null;
  uploadProgress?: number | null;
  uploadFile(file: File): Promise<void>;
  duration?: number;
}

export const UploadContext = React.createContext<UploadContextValue>({
  uploadFile: (file: File) => Promise.resolve(),
});

export interface UploadProviderProps {
  children: React.ReactNode;
}

export const UploadProvider: React.FC<UploadProviderProps> = ({
  children,
}: UploadProviderProps) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [duration, setDuration] = useState<number>(0);

  const { mutateAsync: getUploadUrl } = useGetUploadUrl();
  const { mutateAsync: processClip } = useProcessClip();
  const { progress, mutateAsync: uploadFileAsync } = useUploadFile<File>();

  useEffect(() => {
    setUploadProgress(progress);
  }, [progress]);

  const getVideoDuration = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function () {
        reject('Error loading video');
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const uploadFile = useCallback(
    async (file: File) => {
      try {
        const name = file.name.split('.').shift() as string;

        setFileName(name);
        setUploadProgress(0);

        const duration = await getVideoDuration(file);
        setDuration(duration);

        const { url, id } = await getUploadUrl({
          fileName: name,
        });

        if (url) {
          await uploadFileAsync({ url, data: file });
          await processClip({ id });
        }
      } finally {
        setFileName(null);
        setUploadProgress(null);
      }
    },
    [getUploadUrl, uploadFileAsync, processClip],
  );

  return (
    <UploadContext.Provider value={{ fileName, uploadProgress, uploadFile, duration }}>
      {children}
    </UploadContext.Provider>
  );
};
