import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { TopAndTailPayload } from '../entities/timing';
import Api from './api';
import { MAKE_GET_PROJECT_CACHE_KEY } from './projects';
import EditProject from '@/types//edit-project';

/* istanbul ignore file */

export function useProcessTopAndTail(
  projectId: string,
): UseMutationResult<void, unknown, TopAndTailPayload> {
  const queryClient = useQueryClient();

  return useMutation(
    async (timing: TopAndTailPayload) => {
      await Api.post(`/projects/${projectId}/top-and-tail/process`, timing);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([MAKE_GET_PROJECT_CACHE_KEY(projectId)]),
    },
  );
}

export function useProcessTopAndTailBatch(
  projectId: string,
): UseMutationResult<void, unknown, TopAndTailPayload[]> {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: TopAndTailPayload[]) => {
      await Api.post(`/projects/${projectId}/top-and-tail/batch-process`, { data: payload });
    },
    {
      onSuccess: () => queryClient.invalidateQueries([MAKE_GET_PROJECT_CACHE_KEY(projectId)]),
    },
  );
}

export function useEditSegment(projectId: string): UseMutationResult<void, unknown, EditProject> {
  // const queryClient = useQueryClient();
  return useMutation(async (name: EditProject) => {
    if (name.segmentId) {
      await Api.patch(`/projects/${projectId}/segments/${name.segmentId}`, name);
    } else {
      throw new Error('Segment ID is undefined');
    }
  });
}
