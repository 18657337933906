import moment from 'moment';

class VideoHelper {
  static currentVideoDuration = (startTime: number, endTime: number): number => {
    return moment.utc(endTime * 1000).diff(moment.utc(startTime * 1000));
  };

  static currentVideoProgress = (
    videoDuration: number,
    currentTime: number,
    startTime: number,
  ): number => {
    if (!videoDuration) return 0;
    return ((currentTime - startTime) / videoDuration) * 100;
  };

  static timeToOffset = (containerWidth: number, videoDuration: number, time: number): number => {
    if (!videoDuration) return 0;
    return (time * containerWidth) / videoDuration;
  };

  static timeFromCursorPosition = (
    cursorPositionX: number,
    containerOffset: number,
    containerWidth: number,
    videoDuration: number,
  ): number => {
    if (!containerWidth) return 0;

    const percentOfContainerWidth = (cursorPositionX - containerOffset) / containerWidth;
    return videoDuration * percentOfContainerWidth;
  };

  static formatTime = (time: moment.MomentInput): string => {
    return moment.utc(time).format('m:ss:S');
  };
}

export default VideoHelper;
