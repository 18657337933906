import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import Api from './api';
import AddPlatform from '@/types/integration';
import { IntegrationsList } from '@/types/integration';

export const LIST_INTEGRATIONS_CACHE_KEY = 'list-integrations';

export function useGetIntegrations(): UseQueryResult<IntegrationsList[]> {
  return useQuery(LIST_INTEGRATIONS_CACHE_KEY, async () => {
    return await Api.get('/integrations');
  });
}

export function useAddPlatform(): UseMutationResult<void, unknown, AddPlatform> {
  return useMutation(async (platform: AddPlatform) => {
    if (platform.payload) {
      await Api.post(`/integrations/${platform.platformType}`, {
        platform: {
          [platform.platformType]: platform.payload,
        },
      });
    } else {
      throw new Error('Token not provided');
    }
  });
}

export function useDisconnectIntegration(): UseMutationResult<void, unknown, string, unknown> {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, string, unknown>(
    async (id: string) => {
      await Api.delete(`/integrations/${id}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LIST_INTEGRATIONS_CACHE_KEY]),
      onError: (error: unknown, context: unknown) => {
        alert(error);
      },
    },
  );
}
